/*
Author:      Dimitra Weinstein
Created:     8/23/2022
Modified:    11/10/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  API,
  MILLISECONDS_BETWEEN_POLLING,
  ENABLE_COMBINED_ESTIMATES,
} from "../../../utilities/constants";
import { useHistory, useParams } from "react-router-dom";
import apiRequest from "../../../utilities/apiRequest";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import useApi from "../../../hooks/useApi";
import nowIsoMicro from "../../../utilities/nowIsoMicro";
import EstimateHistoryPage from "../../EstimateHistoryPage/EstimateHistoryPage";
import Success from "../../../components/Success/Success";
import deepCopy from "../../../utilities/deepCopy";
import "./EstimateCalculator.scss";

export default function EstimateCalculator(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [queryMode, setQueryMode] = useState("queryQuote");
  const [itemIdentifier, setItemIdentifier] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [estimateList, setEstimateList] = useState([]);
  const [isPollingApi, setIsPollingApi] = useState(false);
  const [pollingItemIdentifier, setPollingItemIdentifier] = useState("");
  const [pollingQuantity, setPollingQuantity] = useState(0);
  const [pollingRequestTimeUtc, setPollingRequestTimeUtc] = useState("");
  const { topItemIdentifier, requestTimeUtc, path } = useParams();
  const history = useHistory();

  // When changing the query mode, clear all fields.
  useEffect(() => {
    setItemIdentifier("");
    setQuantity(1);
  }, [queryMode]);

  // Check if we are in polling mode for an API endpoint.
  useEffect(() => {
    // Check if the estimate is complete and go to a new page.
    async function getEstimate(itemIdentifier, quantity, requestTimeUtc) {
      if (isPollingApi) {
        const [response, responseBody] = await apiRequest(
          `${API}/estimate/${String(
            itemIdentifier
          ).toUpperCase()}_${requestTimeUtc}`,
          "GET",
          null
        );

        if (response.ok && responseBody && responseBody.estimate) {
          if (
            responseBody.errorMessage === undefined ||
            responseBody.errorMessage === null
          ) {
            history.push(
              `/estimate-calculator/${String(
                itemIdentifier
              ).toUpperCase()}/${requestTimeUtc}/${quantity}/${String(
                itemIdentifier
              ).toUpperCase()}`
            );
          } else {
            setErrorMessage(responseBody.errorMessage);
            setLoading(false);
            setIsPollingApi(false);
          }
        } else if (
          response.statusCode === 404 &&
          responseBody.errorMessage !== undefined
        ) {
          setErrorMessage(responseBody.errorMessage);
          setLoading(false);
          setIsPollingApi(false);
        }
      }
    }

    // Start the timer if we are in polling mode.
    if (isPollingApi) {
      const pollingTimerId = setInterval(
        () =>
          getEstimate(
            pollingItemIdentifier,
            pollingQuantity,
            pollingRequestTimeUtc
          ),
        MILLISECONDS_BETWEEN_POLLING
      );

      return () => {
        clearInterval(pollingTimerId);
      };
    }
  }, [
    isPollingApi,
    pollingItemIdentifier,
    pollingQuantity,
    pollingRequestTimeUtc,
  ]);

  // If we are viewing a child part, get lead time data about the child.
  useApi(
    () => {
      setIsPollingApi(false);
      props.onChangeKeyMap({});
      props.onChangeLeadTimeObject(null);
      props.onChangeQuotedLeadTime(0);
      props.onChangePartsList([]);
      if (topItemIdentifier !== undefined) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
      }
    },
    {
      method: "GET",
      url: `${API}/estimate/${topItemIdentifier}_${requestTimeUtc}`,
      authorization: localStorage.getItem("token"),
    },
    async (response, responseBody) => {
      if (response.ok && responseBody && responseBody.estimate) {
        // Get memo, order identifier, customer code, requestedBy.
        let memo = "";
        let customerCode = "";
        let orderIdentifier = "";

        if (responseBody.memo !== undefined && responseBody.memo !== null) {
          memo = responseBody.memo;
        }
        if (
          responseBody.customerCode !== undefined &&
          responseBody.customerCode !== null
        ) {
          customerCode = responseBody.customerCode;
        }
        if (
          responseBody.orderIdentifier !== undefined &&
          responseBody.orderIdentifier !== null
        ) {
          orderIdentifier = responseBody.orderIdentifier;
        }
        if (
          responseBody.requestedBy !== undefined &&
          responseBody.requestedBy !== null
        ) {
          props.onChangeRequestedBy(responseBody.requestedBy);
        }

        // Find the part that we are using.
        const partPath = path.split("&");
        const itemIdentifier = partPath[partPath.length - 1];
        let currentPart = responseBody.estimate;

        // Get the current estimate type.
        props.onChangeEstimateType(currentPart["estimateType"] || "part");

        if (currentPart.itemIdentifier !== itemIdentifier) {
          partPath.forEach((path) => {
            if (currentPart.estimateList !== undefined) {
              currentPart.estimateList.forEach((part) => {
                if (part.itemIdentifier === path) {
                  currentPart = part;
                  props.onChangeEstimateType(
                    currentPart["estimateType"] || "part"
                  );
                }
              });
            }
          });
        }

        currentPart.memo = memo;
        currentPart.customerCode = customerCode;
        currentPart.orderIdentifier = orderIdentifier;

        if (currentPart.itemIdentifier !== itemIdentifier) {
          history.push("/estimate-calculator/not-found");
          return;
        }

        setItemIdentifier(currentPart.itemIdentifier);
        setQuantity(1);
        props.onChangeKeyMap(responseBody.keyMap);
        props.onChangeLeadTimeObject(currentPart);
        props.onChangeQuotedLeadTime(currentPart.quotedLeadTime);
        props.onChangePartsList(currentPart.estimateList);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get estimate data.");
      }
      setLoading(false);
    },
    [topItemIdentifier, requestTimeUtc, path]
  );

  // Validate the query.
  function queryIsValid(itemIdentifier, quantity, estimateList) {
    if (queryMode === "queryPart" && itemIdentifier === "") {
      setErrorMessage("A part number is required.");
      return false;
    } else if (queryMode === "queryPart" && quantity <= 0) {
      setErrorMessage("Quantity must be a number greater than zero.");
      return false;
    } else if (queryMode === "queryQuote" && itemIdentifier === "") {
      setErrorMessage("A quote number is required.");
      return false;
    } else if (queryMode === "queryEstimates" && estimateList.length < 2) {
      setErrorMessage("At least two estimates must be selected.");
      return false;
    } else {
      return true;
    }
  }

  // Query lead time data.
  function queryLeadTime(itemIdentifier, quantity, estimateList) {
    if (queryIsValid(itemIdentifier, quantity, estimateList)) {
      setErrorMessage("");
      calculateEstimate(itemIdentifier, quantity, estimateList);
    }
  }

  // Make an API call to get estimate data.
  async function calculateEstimate(itemIdentifier, quantity, estimateList) {
    props.onChangeKeyMap({});
    props.onChangeLeadTimeObject(null);
    props.onChangeQuotedLeadTime(0);
    props.onChangePartsList([]);
    const newRequestTimeUtc = nowIsoMicro();

    // Make Lead Time History API Request.
    const apiUrl = `${API}/estimate`;

    let requestBody = {};

    switch (queryMode) {
      case "queryQuote":
        requestBody = {
          quoteIdentifier: String(itemIdentifier),
          quantity: null,
          partIdentifier: null,
          requestTimeUtc: newRequestTimeUtc,
          estimateList: null,
        };
        break;
      case "queryPart":
        requestBody = {
          quoteIdentifier: null,
          quantity: parseFloat(quantity),
          partIdentifier: String(itemIdentifier).toUpperCase(),
          requestTimeUtc: newRequestTimeUtc,
          estimateList: null,
        };
        break;
      case "queryEstimates":
        requestBody = {
          quoteIdentifier: null,
          quantity: null,
          partIdentifier: null,
          requestTimeUtc: newRequestTimeUtc,
          estimateList: estimateList,
        };
        break;
      default:
        requestBody = {
          quoteIdentifier: String(itemIdentifier).toUpperCase(),
          quantity: null,
          partIdentifier: null,
          requestTimeUtc: newRequestTimeUtc,
          estimateList: null,
        };
    }

    // Make first API request and then start polling to see when it resolves.
    setLoading(true);
    apiRequest(apiUrl, "POST", requestBody);

    setPollingItemIdentifier(itemIdentifier);
    setPollingQuantity(quantity);
    setPollingRequestTimeUtc(newRequestTimeUtc);
    setIsPollingApi(true);
  }

  // Select a single estimate (or deselect is already selected).
  function selectEstimate(estimate) {
    const estimateListDeepCopy = deepCopy(estimateList);
    if (estimateListDeepCopy.includes(estimate)) {
      estimateListDeepCopy.splice(estimateListDeepCopy.indexOf(estimate), 1);
    } else {
      estimateListDeepCopy.push(estimate);
    }
    setEstimateList(estimateListDeepCopy);
  }

  // Update the quote number.
  function updateQuote(quoteNumber) {
    const integerQuote = parseInt(quoteNumber);
    setItemIdentifier(integerQuote);
  }

  // Clear all estimate request information.
  function clearQuery() {
    setQuantity(1);
    setItemIdentifier("");
    setEstimateList([]);
    setIsPollingApi(false);
    setPollingItemIdentifier("");
    setPollingQuantity(0);
    setPollingRequestTimeUtc("");
    setLoading(false);
  }

  return (
    <Fragment>
      <Spinner loading={loading} />

      {/* Lead Time Calculator Form */}
      {topItemIdentifier !== undefined ? (
        <Fragment>
          {errorMessage.length > 0 && (
            <div className="mt-4">
              <Error message={errorMessage} />
            </div>
          )}
        </Fragment>
      ) : (
        <div
          style={{ width: "850px", minHeight: "230px" }}
          className="card my-4 mx-auto"
        >
          <div className={`card-header lead-time-header estimate-calculator`}>
            Calculate Lead Time Estimate
          </div>
          <div className="card-body">
            <div className="row mt-3 mx-3">
              <div className="col">
                <select
                  name="select-query-type"
                  className="form-select"
                  disabled={isPollingApi}
                  value={queryMode}
                  onChange={(e) => setQueryMode(e.target.value)}
                >
                  <option value="queryQuote">
                    Get an estimate with a quote number
                  </option>
                  <option value="queryPart">
                    Get an estimate with a part number
                  </option>
                  {ENABLE_COMBINED_ESTIMATES && (
                    <option value="queryEstimates">
                      Select estimates to combine
                    </option>
                  )}
                </select>
              </div>
            </div>
            <div className="row mt-3 mx-3">
              {queryMode === "queryQuote" && (
                <div className="col-9">
                  <label className="control-label px-1">
                    <span>Quote Number</span>
                  </label>
                  <input
                    type="number"
                    className="form-control mx-1 text-center"
                    min="0"
                    step="1"
                    placeholder="Enter Quote Number"
                    disabled={isPollingApi}
                    value={itemIdentifier}
                    onChange={(e) => updateQuote(e.target.value)}
                  />
                </div>
              )}

              {queryMode === "queryPart" && (
                <Fragment>
                  <div className="col-5">
                    <label className="control-label px-1">
                      <span>Part Number</span>
                    </label>
                    <input
                      type="text"
                      className="form-control mx-1 text-center"
                      placeholder="Enter Part Number"
                      disabled={isPollingApi}
                      value={itemIdentifier}
                      onChange={(e) => setItemIdentifier(e.target.value)}
                    />
                  </div>

                  <div className="col-4">
                    <label className="control-label px-1">Quantity</label>
                    <input
                      type="number"
                      min="1"
                      className="form-control mx-1 text-center"
                      placeholder="Enter Quantity"
                      disabled={isPollingApi}
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                </Fragment>
              )}

              <div
                className={
                  queryMode === "queryEstimates"
                    ? "col-12 text-center"
                    : "col-3"
                }
              >
                <button
                  className="submit-button btn btn-success mt-4"
                  disabled={isPollingApi}
                  onClick={() =>
                    queryLeadTime(itemIdentifier, quantity, estimateList)
                  }
                >
                  Calculate Lead Time
                </button>
              </div>
            </div>
            {errorMessage.length > 0 && (
              <div className="mt-4 mx-4">
                <Error message={errorMessage} />
              </div>
            )}

            {errorMessage.length === 0 && isPollingApi && (
              <div className="mt-4 mx-4 text-center">
                <Success
                  message={
                    "Your estimate is being processed. Either wait here or view your estimate later in 'Estimate History'." +
                    " Leaving this page won't stop this query."
                  }
                />

                <button
                  className="btn btn-primary mx-auto"
                  onClick={() => clearQuery()}
                >
                  Start a New Estimate
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Estimate search for selecting estimates to combine. */}
      {queryMode === "queryEstimates" && (
        <EstimateHistoryPage
          mode="combine"
          estimateSelections={estimateList}
          onSelect={(estimate) => selectEstimate(estimate)}
        />
      )}
    </Fragment>
  );
}

EstimateCalculator.propTypes = {
  keyMap: PropTypes.object.isRequired,
  leadTimeObject: PropTypes.object,
  quotedLeadTime: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  rowLimit: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onChangeKeyMap: PropTypes.func.isRequired,
  onChangeLeadTimeObject: PropTypes.func.isRequired,
  onChangeQuotedLeadTime: PropTypes.func.isRequired,
  onChangePartsList: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeRowLimit: PropTypes.func.isRequired,
  onChangePageNumber: PropTypes.func,
  onChangePageCount: PropTypes.func.isRequired,
  onChangeEstimateType: PropTypes.func.isRequired,
  onChangeRequestedBy: PropTypes.func.isRequired,
};
