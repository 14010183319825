/*
Author:      Zachary Thomas
Created:     6/9/2021
Modified:    6/9/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./Success.css";

// Success message.
function Success(props) {
  return (
    (props.message.length ? (
      <div className="alert alert-success" role="alert">
        {props.message}
      </div>
    ) : (
      null
    ))
  );
}

export default Success;

Success.propTypes = {
  message: PropTypes.string.isRequired
};