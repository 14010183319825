/*
Author:      Dimitra Weinstein
Created:     7/25/2022
Modified:    9/21/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";

export default function DateSelection(props) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-5 mt-2">
          <label className="control-label px-1">
            <strong>Start</strong>
          </label>
          <div>
            <input
              className="mt-1 mx-auto"
              type="date"
              value={props.startDate}
              onChange={(event) => props.onStartDateChange(event.target.value)} />
          </div>
        </div>
        <div className="col mt-2">
          <label className="control-label px-2">
            <strong>End</strong>
          </label>
          <div>
            <input
              className="mt-1 mx-2"
              type="date"
              value={props.endDate}
              onChange={(event) => props.onEndDateChange(event.target.value)} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

DateSelection.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired
};