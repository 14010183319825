/*
Author:      Dimitra Weinstein
Created:     8/24/2022
Modified:    10/5/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ComponentPartListRow from "./ComponentPartListRow/ComponentPartListRow";
import "./ComponentPartList.css";
import { useParams } from "react-router-dom";

export default function ComponentPartList(props) {
  const { path } = useParams();

  return (
    <Fragment>
      <div className="accordion accordion-flush show mb-4 component-part-list"
        id="componentAccordion">
        <div className="accordion-item">
          <div
            style={{ cursor: "pointer", height: "50px" }}
            className="lead-time-header component-part-list text-white accordion-header accordion-button"
            id="accordion-headingTwo"
            accordion-icon-color="white"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <p className="fs-6 mx-2 my-1">
              Component Parts list
            </p>
          </div>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse show"
            data-bs-parent="#componentAccordion">
            <div className="accordion-body">
              {Array.isArray(props.partsList) &&
                props.partsList.length > 0 ?
                props.partsList.map((item, i) =>
                  <ComponentPartListRow
                    key={i}
                    item={item}
                    path={`${path}&${item.itemIdentifier}`}
                    indentationLevel={0}
                  />
                ) : (
                  <div className="no-component-parts-text mx-auto my-5 text-center">
                    There are no parts to display.
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

ComponentPartList.propTypes = {
  partsList: PropTypes.array.isRequired
};