/*
Author:      Dimitra Weinstein
Created:     7/28/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useContext } from "react";
import apiRequest from "../../utilities/apiRequest";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import { API } from "../../utilities/constants";
import EstimateHistoryTable from "./EstimateHistoryTable/EstimateHistoryTable";
import PropTypes from "prop-types";
import Context from "../../components/Context/Context";
import changeKeyName from "../../utilities/changeKeyName";
import deepCopy from "../../utilities/deepCopy";
import "./EstimateHistoryPage.css";

export default function EstimateHistoryPage(props) {
  const [errorMessage] = useState("");
  const [successfulQuery, setSuccessfulQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadTimeHistory, setLeadTimeHistory] = useState([]);
  const [filteredItemIdentifier, setFilteredItemIdentifier] = useState("");
  const [filteredMemo, setFilteredMemo] = useState("");
  const [filteredCompanyCustomerCode, setFilteredCompanyCustomerCode] =
    useState("");
  const [filteredOrderIdentifier, setFilteredOrderIdentifier] = useState("");
  const [showOnlyMine, setShowOnlyMine] = useState(true);
  const [showOnlySaved, setShowOnlySaved] = useState(true);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumnIndex, setSortColumnIndex] = useState(1);
  const context = useContext(Context);

  // Set the basic theme.
  useEffect(() => {
    context.setTheme("basic");
  }, []);

  // Sort estimates when the sorting order changes.
  useEffect(() => {
    const leadTimeHistoryDeepCopy = deepCopy(leadTimeHistory);
    setLeadTimeHistory(sortEstimates(leadTimeHistoryDeepCopy));
  }, [sortAscending, sortColumnIndex]);

  // Sort estimates.
  function sortEstimates(estimates) {
    return estimates.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = new Date(a.requestTimeUtc);
          bValue = new Date(b.requestTimeUtc);
          break;
        case 2:
          aValue = a.itemIdentifier;
          bValue = b.itemIdentifier;
          break;
        case 3:
          aValue = a.requestedBy;
          bValue = b.requestedBy;
          break;
        case 4:
          aValue = a.customerCode;
          bValue = b.customerCode;
          break;
        case 5:
          aValue = a.memo;
          bValue = b.memo;
          break;
        case 6:
          aValue = a.orderIdentifier;
          bValue = b.orderIdentifier;
          break;
        case 7:
          aValue = a.isSaved;
          bValue = b.isSaved;
          break;
        default:
          aValue = new Date(a.requestTimeUtc);
          bValue = new Date(b.requestTimeUtc);
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  // Search lead time estimates with the specified filter fields.
  async function searchEstimates() {
    setLoading(true);
    const apiUrl = `${API}/estimate/search`;
    const email = localStorage.getItem("email");
    const requestBody = {
      partIdentifier: null,
      customerCode: null,
      orderIdentifier: null,
      memo: null,
      requestedBy: null,
      isSaved: null,
    };
    if (filteredItemIdentifier.length > 0) {
      requestBody.partIdentifier = filteredItemIdentifier;
    }
    if (filteredCompanyCustomerCode.length > 0) {
      requestBody.customerCode = filteredCompanyCustomerCode;
    }
    if (filteredOrderIdentifier.length > 0) {
      requestBody.orderIdentifier = filteredOrderIdentifier;
    }
    if (filteredMemo.length > 0) {
      requestBody.memo = filteredMemo;
    }
    if (showOnlyMine && email !== undefined && email.length > 0) {
      requestBody.requestedBy = email;
    }
    if (showOnlySaved) {
      requestBody.isSaved = showOnlySaved;
    }

    const [response, responseBody] = await apiRequest(
      apiUrl,
      "POST",
      requestBody
    );
    setLoading(false);

    if (response.ok && responseBody) {
      setSuccessfulQuery(true);
      responseBody.leadTime.forEach((item) =>
        changeKeyName(item, "partNumber-delayItem-config", "itemIdentifier")
      );
      setLeadTimeHistory(sortEstimates(responseBody.leadTime));
    } else {
      setSuccessfulQuery(false);
    }
  }

  return (
    <div>
      <Spinner loading={loading} />
      <div className="leadtime-history-page card mx-auto my-4">
        <div className={`card-header lead-time-header estimate-history`}>
          Lead Time Estimates
        </div>
        <div className="card-body">
          <div className="row mb-3 mx-auto">
            <div className="col-2 mt-2">
              <div>
                <label className="control-label px-1">
                  <strong>Part Identifier</strong>
                </label>
                <input
                  className="form-control mt-1 mx-1"
                  type="text"
                  placeholder="Filter part number..."
                  value={filteredItemIdentifier}
                  onChange={(e) => setFilteredItemIdentifier(e.target.value)}
                />
              </div>
            </div>
            <div className="col-2 mt-2">
              <label className="control-label px-1">
                <strong>Filter By Memo</strong>
              </label>
              <input
                className="form-control mt-1 mx-1"
                type="text"
                placeholder="Filter memo..."
                value={filteredMemo}
                onChange={(e) => setFilteredMemo(e.target.value)}
              />
            </div>
            <div className="col-2 mt-2">
              <label>
                <strong>Filter By Customer Code</strong>
              </label>
              <input
                className="form-control mt-1 mx-1"
                type="text"
                placeholder="Filter customer code..."
                value={filteredCompanyCustomerCode}
                onChange={(e) => setFilteredCompanyCustomerCode(e.target.value)}
              />
            </div>
            <div className="col-2 mt-2">
              <label>
                <strong>Filter By Order Number</strong>
              </label>
              <input
                className="form-control mt-1 mx-1"
                type="text"
                placeholder="Filter order number..."
                value={filteredOrderIdentifier}
                onChange={(e) => setFilteredOrderIdentifier(e.target.value)}
              />
            </div>
            <div className="col-2 text-center ">
              <div className="mt-2 mb-1">
                <strong>Show Only My Estimates</strong>
              </div>
              <input
                className="form-check-input form-check-big"
                type="checkbox"
                checked={showOnlyMine}
                onChange={() => setShowOnlyMine((prev) => !prev)}
              />
            </div>
            <div className="col-2 text-center ">
              <div className="mt-2 mb-1">
                <strong>Show Only Saved Estimates</strong>
              </div>
              <input
                className="form-check-input form-check-big"
                type="checkbox"
                checked={showOnlySaved}
                onChange={() => setShowOnlySaved((prev) => !prev)}
              />
            </div>
            <div className="col-2">
              <button
                type="button"
                className="btn btn-success mt-4"
                onClick={() => searchEstimates()}
              >
                Search Estimates
              </button>
            </div>
          </div>
          {successfulQuery && errorMessage.length === 0 && (
            <div>
              {leadTimeHistory.length > 0 ? (
                <EstimateHistoryTable
                  leadTimeHistory={leadTimeHistory}
                  mode={props.mode}
                  estimateSelections={props.estimateSelections}
                  sortAscending={sortAscending}
                  sortColumnIndex={sortColumnIndex}
                  onSelect={(estimate) => props.onSelect(estimate)}
                  onChangeSortAscending={(sortAscending) =>
                    setSortAscending(sortAscending)
                  }
                  onChangeSortColumnIndex={(sortColumnIndex) =>
                    setSortColumnIndex(sortColumnIndex)
                  }
                />
              ) : (
                <div className="big-message mx-auto my-5 text-center">
                  No Lead Time History data to display.
                </div>
              )}
            </div>
          )}
          <div className="mt-4">
            <Error message={errorMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}

EstimateHistoryPage.propTypes = {
  mode: PropTypes.string.isRequired,
  estimateSelections: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};
