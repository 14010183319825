/*
Author:      Dimitra Weinstein
Created:     8/15/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import DelayEventTableRow from "./DelayEventTableRow/DelayEventTableRow";
import TableSortedHeaderColumn from "../../../components/TableSortedHeaderColumn/TableSortedHeaderColumn";
import "./DelayEventTable.scss";

// List of delay events that support creation, updating and deletion.
export default function DelayEventTable(props) {
  // Update sorting order.
  function updateSort(newIndex) {
    if (newIndex === props.sortColumnIndex) {
      props.onChangeSortAscending(!props.sortAscending);
    } else {
      props.onChangeSortAscending(false);
      props.onChangeSortColumnIndex(newIndex);
    }
  }

  return (
    <div>
      <div className="delay-event-card row card mx-auto">
        <div className="delay-event-header card-header text-dark font-weight-bold mb-1 px-2 py-2">
          <div className="col mx-1">
            <span className="delay-title-text ms-2">Delay Event History</span>
            <div className="col mb-1 float-end">
              {/* Button for creating new events */}
              <button
                className="create-btn btn btn-sm btn-light font-weight-bold"
                onClick={() => props.onSelect(0)}
              >
                Create Event
              </button>
            </div>
          </div>
        </div>

        <div className="card-body">
          {props.events.length > 0 ? (
            <table className="delay-event-table col-xxl-2 table table-sm table-bordered table-responsive table-striped table-hover mx-auto">
              <thead className={`table-header text-dark delay-events`}>
                <tr>
                  <TableSortedHeaderColumn
                    name="Date"
                    index={1}
                    selectedIndex={props.sortColumnIndex}
                    selectedAscending={props.sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                  <TableSortedHeaderColumn
                    name="Severity"
                    index={2}
                    selectedIndex={props.sortColumnIndex}
                    selectedAscending={props.sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                  <TableSortedHeaderColumn
                    name="Category"
                    index={3}
                    selectedIndex={props.sortColumnIndex}
                    selectedAscending={props.sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                  <TableSortedHeaderColumn
                    name="Memo"
                    index={4}
                    selectedIndex={props.sortColumnIndex}
                    selectedAscending={props.sortAscending}
                    onClick={(index) => updateSort(index)}
                  />
                </tr>
              </thead>
              <tbody>
                {/* List of interactive events */}
                {!props.loading &&
                  props.events.map((event) => (
                    <DelayEventTableRow
                      key={event.identifier}
                      selectedEvent={event}
                      onSelect={() => props.onSelect(event.identifier)}
                    />
                  ))}
              </tbody>
            </table>
          ) : (
            <div className="no-events-text mx-auto my-5 text-center">
              There are no events to display.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

DelayEventTable.propTypes = {
  loading: PropTypes.bool,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      severity: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      memo: PropTypes.string,
    })
  ).isRequired,
  sortAscending: PropTypes.bool.isRequired,
  sortColumnIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChangeSortAscending: PropTypes.func.isRequired,
  onChangeSortColumnIndex: PropTypes.func.isRequired,
};
