/*
Author:      Dimitra Weinstein
Created:     7/13/2022
Modified:    9/22/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Paginate through timephase production schedule content.
export default function PaginationButtons(props) {

  return (
    <Fragment>
      <div className="row mx-auto text-center">
        <label className="col-9 col-xxl-9 mb-1 control-label px-1">
          <strong>
            Pages
          </strong>
        </label>
      </div>
      <div className="row btn-toolbar mx-auto" role="toolbar">
        <div className="btn col-12 col-md-12 col-xxl-9 px-1">
          {props.pageNumber > 1 && (
            <button
              type="button"
              className="col-auto col-xxl-3 mx-2 btn btn-success"
              onClick={() => props.onPageChange(1)}>
              <strong>&lt;&lt;</strong>
            </button>
          )}
          {props.pageNumber > 2 && (
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={() => props.onPageChange(props.pageNumber - 2)}>
              <strong>
                {props.pageNumber - 2}
              </strong>
            </button>
          )}
          {props.pageNumber > 1 && (
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={() => props.onPageChange(props.pageNumber - 1)}>
              <strong>
                {props.pageNumber - 1}
              </strong>
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary mx-1"
            onClick={() => props.onPageChange(props.pageNumber)}>
            <strong>
              {props.pageNumber}
            </strong>
          </button>
          {props.pageNumber + 1 <= props.pageCount && (
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={() => props.onPageChange(props.pageNumber + 1)}>
              <strong>
                {props.pageNumber + 1}
              </strong>
            </button>
          )}
          {props.pageNumber + 2 <= props.pageCount && (
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={() => props.onPageChange(props.pageNumber + 2)}>
              <strong>
                {props.pageNumber + 2}
              </strong>
            </button>
          )}
          {props.pageNumber + 1 <= props.pageCount && (
            <button
              type="button"
              className="col-auto col-xxl-3 mx-2 btn btn-success"
              onClick={() => props.onPageChange(props.pageCount)}>
              <strong>
                &gt;&gt;
              </strong>
            </button>
          )}
        </div>
      </div>
      <div className="row mx-auto text-center">
        <label className="col-9 col-xxl-9 mt-2 control-label px-1">
          <strong>
            Total Pages: {props.pageCount}
          </strong>
        </label>
      </div>
    </Fragment>
  );
}

PaginationButtons.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
  rowLimit: PropTypes.string,
  timePhaseData: PropTypes.array,
  filteredLeadTimeHistory: PropTypes.array,
  onPageChange: PropTypes.func.isRequired
};
