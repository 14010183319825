/*
Author:      Zachary Thomas
Created:     4/8/2021
Modified:    4/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./TextBlurb.css";

// Title, paragraph, and icon text.
function TextBlurb(props) {
  return (
    <div className="text-blurb">
      <h1 className="text-blurb-title">
        {props.title}
      </h1>
      <p className="text-blurb-paragraph">
        {props.paragraph}
      </p>
      <i className={`text-blurb-icon fa fa-fw fa-${props.icon} fa-3x`} />
    </div>
  );
}

export default TextBlurb;

TextBlurb.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  icon: PropTypes.string
};