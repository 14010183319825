/*
Author:      Grayson Fleming
Created:     5/10/2021
Modified:    9/9/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Format time to the local value
export function formatTime(timestamp) {
  const utcDate = new Date(Date.parse(timestamp));
  const offset = new Date().getTimezoneOffset() / 60;
  const curTime = utcDate.setHours(utcDate.getHours() - offset);

  if (new Date(curTime).getTime() > 0 && parseInt(timestamp) > 1000) {
    return new Date(curTime)
      .toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      });
  } else {
    return timestamp;
  }
}