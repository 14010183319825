/*
Author:      Dimitra Weinstein
Created:     8/23/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Info from "../Info/Info";
import { INVALID_INFO_FIELD_KEYS } from "../../../utilities/constants";
import { camelCaseToTitleCase } from "../../../utilities/camelCaseToTitleCase";
import "./EstimateInfoCard.css";

export default function EstimateInfoCard(props) {
  const [records, setRecords] = useState([]);

  // Get all valid info values / tooltips. Filter out invalid fields.
  useEffect(() => {
    const tempRecords = [];
    for (const [key, value] of Object.entries(props.leadTimeObject)) {
      const newRecord = { keyName: key, value: value };
      if (!INVALID_INFO_FIELD_KEYS.includes(key)) {
        tempRecords.push(newRecord);
      }
    }
    setRecords(tempRecords);
  }, [JSON.stringify([props.leadTimeObject])]);

  return (
    <div className="mx-auto mb-4">
      <div className="card">
        <div className={`lead-time-header text-white estimate-results`}>
          <p className="fs-6 mx-2 px-2 mt-2">Estimate Details</p>
        </div>
        <div className="card-body">
          <div className="container col-12">
            <div className="row mx-auto mt-2">
              <h5>General Details</h5>
              {records.map((record, i) => (
                <div key={record.keyName} className="col-12 col-md-6 col-xxl-4">
                  <b>{props.keyMapping[record.keyName].name}</b>
                  <Info
                    fieldId={i}
                    fieldName={props.keyMapping[record.keyName].name}
                    toolTipText={props.keyMapping[record.keyName].description}
                  />
                  <span>:&nbsp;</span>
                  <span
                    className={record.value === "" ? "empty-info-text" : ""}
                  >
                    {record.value === ""
                      ? "information not found"
                      : `${props.keyMapping[record.keyName].prefix}${
                        record.value
                      } ${props.keyMapping[record.keyName].postfix}`}
                  </span>
                </div>
              ))}

              {/* Show all configurations that were applied to this estimate. */}
              {props.leadTimeObject.configurationsApplied !== undefined &&
                props.leadTimeObject.configurationsApplied.length > 0 && (
                <div className="mt-5">
                  <h5>Configurations Applied</h5>
                  <div className="row mx-auto mt-3">
                    {props.leadTimeObject.configurationsApplied.map(
                      (configuration, i) => (
                        <div key={i} className="col-12 col-md-6 col-xxl-4">
                          <b>{camelCaseToTitleCase(configuration.name)}</b>
                          <Info
                            fieldId={configuration.name}
                            fieldName={
                              props.keyMapping[configuration.name].name
                            }
                            toolTipText={
                              props.keyMapping[configuration.name].description
                            }
                          />
                          <span>: {configuration.value} days</span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EstimateInfoCard.propTypes = {
  keyMapping: PropTypes.object,
  leadTimeObject: PropTypes.object,
};
