/*
Author:      Zachary Thomas
Created:     6/8/2021
Modified:    10/4/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoginForm from "./LoginForm/LoginForm";
import { useHistory } from "react-router-dom";
import { API } from "../../utilities/constants";
import apiRequest from "../../utilities/apiRequest";
import Context from "../../components/Context/Context";
import "./LoginPage.css";

// Page for users to login to their account.
function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const context = useContext(Context);

  // Set default role and theme.
  useEffect(() => {
    context.setRole("");
    context.setTheme("basic");
  }, []);

  // Attempts to login a user using the Cognito API.
  async function submit(email, password) {
    if (!email.length) {
      setError("Please enter an email.");
    } else if (!password.length) {
      setError("Please enter a password.");
    } else {

      setLoading(true);
      const requestBody = {
        Username: email,
        Password: password
      };
      let response = null;
      let responseBody = null;
      localStorage.setItem("token", "");
      [response, responseBody] = await apiRequest(
        `${API}/authenticate`,
        "POST",
        requestBody
      );
      setLoading(false);
      if (response.ok && responseBody) {
        localStorage.setItem("token", responseBody.token);
        context.setRole(responseBody.userRole);
        localStorage.setItem("userRole", responseBody.userRole);
        localStorage.setItem("email", requestBody.Username);

        if (responseBody.userRole === "USER") {
          history.push("/estimate-calculator");
        } else if (responseBody.userRole === "OPERATIONS") {
          history.push("/delay-events");
        } else if (responseBody.userRole === "ADMIN") {
          history.push("/global-configuration");
        }
      } else {
        setError("Username and password are not valid");
      }
    }
  }

  return (
    <div className="page-login">
      <Spinner loading={loading} />
      <PageTitle title="Fulfillment Lead Time" />

      <LoginForm
        errorMessage={error}
        onSubmit={(email, password) => submit(email, password)}
      />
    </div>
  );
}

export default LoginPage;