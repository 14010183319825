/*
Author:      Zachary Thomas
Created:     10/11/2022
Modified:    10/11/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import PrinterSafePartListRow from "./PrinterSafePartListRow/PrinterSafePartListRow";
import { INVALID_INFO_FIELD_KEYS } from "../../../utilities/constants";
import { formatDateSlashes } from "../../../utilities/formatDateSlashes";
import { camelCaseToTitleCase } from "../../../utilities/camelCaseToTitleCase";
import "./PrinterSafeEstimatePage.scss";

// printer safe estimate page.
export default function PrinterSafeEstimatePage(props) {
  const [records, setRecords] = useState([]);

  // Get all valid info values / tooltips. Filter out invalid fields.
  useEffect(() => {
    const tempRecords = [];

    if (props.leadTimeObject !== undefined && props.leadTimeObject !== null) {
      for (const [key, value] of Object.entries(props.leadTimeObject)) {
        const newRecord = { keyName: key, value: value };
        if (!INVALID_INFO_FIELD_KEYS.includes(key)) {
          tempRecords.push(newRecord);
        }
      }
    }

    setRecords(tempRecords);
  }, [JSON.stringify([props.leadTimeObject])]);

  return (
    <div className="printer-safe-estimate-page">
      {props.leadTimeObject !== null && (
        <div>
          <h5>Estimate Results</h5>
          <div>
            {props.quotedLeadTime === 1 ? (
              <span>{props.quotedLeadTime} day to fulfillment</span>
            ) : (
              <span>{props.quotedLeadTime} days to fulfillment</span>
            )}

            {props.leadTimeObject.estimateType === "part" ? (
              <Fragment>
                {parseInt(props.quantity, 10) === 1 ? (
                  <span>
                    &nbsp;for 1 count of {props.leadTimeObject.itemIdentifier}
                  </span>
                ) : (
                  <span>
                    &nbsp;for {props.quantity} counts of{" "}
                    {props.leadTimeObject.itemIdentifier}
                  </span>
                )}
              </Fragment>
            ) : (
              <span>
                &nbsp;for the quote {props.leadTimeObject.itemIdentifier}
              </span>
            )}

            <br />
            <span>
              Expected fulfillment date {formatDateSlashes(props.leadTimeObject.fulfillmentDate)}
            </span>
          </div>

          <h5 className="mt-2">Estimate Details</h5>
          <div>
            {records.map((record) => (
              <div key={record.keyName} className="col-12 col-md-6 col-xxl-4">
                <span>{props.keyMapping[record.keyName].name}:&nbsp;</span>
                <span className={record.value === "" ? "empty-info-text" : ""}>
                  {record.value === ""
                    ? "information not found"
                    : `${props.keyMapping[record.keyName].prefix}${
                      record.value
                    } ${props.keyMapping[record.keyName].postfix}`}
                </span>
              </div>
            ))}

            {props.leadTimeObject.configurationsApplied !== undefined && props.leadTimeObject.configurationsApplied.map(
              (configuration, i) => (
                <div key={i} className="col-12 col-md-6 col-xxl-4">
                  {camelCaseToTitleCase(configuration.name)}
                  <span>: {configuration.value} days</span>
                </div>
              )
            )}
          </div>

          {props.partsList.length > 0 && (
            <Fragment>
              <h5 className="mt-2">Component Parts list</h5>
              <div>
                {props.partsList.map((item, i) => (
                  <PrinterSafePartListRow
                    key={i}
                    item={item}
                    indentationLevel={0}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

PrinterSafeEstimatePage.propTypes = {
  quotedLeadTime: PropTypes.number,
  quantity: PropTypes.number,
  leadTimeObject: PropTypes.object,
  keyMapping: PropTypes.object,
  partsList: PropTypes.array,
};
