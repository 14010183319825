/*
Author:      Dimitra Weinstein
Created:     9/19/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import apiRequest from "../../../utilities/apiRequest";
import useApi from "../../../hooks/useApi";
import { API } from "../../../utilities/constants";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Modal from "../../../components/Modal/Modal";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import SaveEstimateForm from "./SaveEstimateForm/SaveEstimateForm";
import deepCopy from "../../../utilities/deepCopy";
import "./SaveEstimateModal.css";

export default function SaveEstimateModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [orderIdentifier, setOrderIdentifier] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [customerCodes, setCustomerCodes] = useState([]);
  const [memo, setMemo] = useState("");

  // Get the list of valid customers.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/customer`,
      authorization: localStorage.getItem("token"),
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        const tempCustomerCodes = [];
        responseBody.customers.forEach((customer) =>
          tempCustomerCodes.push(customer.customerCode)
        );
        setCustomerCodes(tempCustomerCodes);
      } else {
        if (response.status >= 500) {
          setErrorMessage(
            "Internal server error. Unable to get customer data."
          );
        }
      }
      setLoading(false);
    },
    []
  );

  // Update state when selected query prop changes.
  useEffect(() => {
    if (props.showModal) {
      if (typeof props.leadTimeObject.orderIdentifier === "string") {
        setOrderIdentifier(props.leadTimeObject.orderIdentifier);
      }
      if (typeof props.leadTimeObject.memo === "string") {
        setMemo(props.leadTimeObject.memo);
      }
      if (typeof props.leadTimeObject.customerCode === "string") {
        setCustomerCode(props.leadTimeObject.customerCode);
      }
    }
  }, [props.showModal, JSON.stringify(props.leadTimeObject)]);

  // Validate Query.
  function queryIsValid() {
    if (memo === "") {
      setErrorMessage("A memo is required.");
      return false;
    } else if (customerCode === "") {
      setErrorMessage(`A customer code is required.`);
      return false;
    } else if (!customerCodes.includes(customerCode)) {
      setErrorMessage(`You have entered an invalid customer code.`);
      return false;
    } else {
      return true;
    }
  }

  async function createSavedEstimate() {
    if (queryIsValid()) {
      setLoading(true);
      const apiUrl = `${API}/estimate/${props.itemIdentifier}`;
      const requestBody = {
        requestTimeUtc: props.requestTimeUtc,
        requestedBy: props.requestedBy,
        orderIdentifier: orderIdentifier,
        customerCode: customerCode,
        memo: memo,
        toSave: true,
      };
      const [response, responseBody] = await apiRequest(
        apiUrl,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        const leadTimeObjectDeepCopy = deepCopy(props.leadTimeObject);
        leadTimeObjectDeepCopy.customerCode = customerCode;
        leadTimeObjectDeepCopy.memo = memo;
        leadTimeObjectDeepCopy.orderIdentifier = orderIdentifier;
        props.onChange(leadTimeObjectDeepCopy);
        dontSaveChanges();
      } else {
        setErrorMessage("Internal server error. Unable to save estimate.");
      }
    }
  }

  // Edit saved query.
  async function editSavedEstimate() {
    if (queryIsValid()) {
      setLoading(true);
      const apiUrl = `${API}/estimate/${props.itemIdentifier}`;
      const requestBody = {
        identifier: `${props.itemIdentifier}_${props.requestTimeUtc}`,
        requestTimeUtc: props.requestTimeUtc,
        requestedBy: props.requestedBy,
        orderIdentifier: orderIdentifier,
        customerCode: customerCode,
        memo: memo,
        toSave: true,
      };

      const [response, responseBody] = await apiRequest(
        apiUrl,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        const leadTimeObjectDeepCopy = deepCopy(props.leadTimeObject);
        leadTimeObjectDeepCopy.customerCode = customerCode;
        leadTimeObjectDeepCopy.memo = memo;
        leadTimeObjectDeepCopy.orderIdentifier = orderIdentifier;
        props.onChange(leadTimeObjectDeepCopy);
        dontSaveChanges();
      } else {
        setErrorMessage("Internal server error. Unable to save estimate.");
      }
    }
  }

  // Delete Saved Query.
  async function deleteSavedEstimate() {
    setLoading(true);
    const apiUrl = `${API}/estimate/${props.itemIdentifier}`;
    const requestBody = { requestTimeUtc: props.requestTimeUtc };
    const [response, responseBody] = await apiRequest(
      apiUrl,
      "DELETE",
      requestBody
    );
    setLoading(false);
    setShowConfirmDelete(false);
    if (response.ok && responseBody) {
      props.onClose();
      history.push("/estimate-history");
    } else {
      setErrorMessage("Internal server error. Unable to delete query.");
    }
  }

  // Exit Modal if no changes have been made. Otherwise, prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      orderIdentifier === (props.leadTimeObject.orderIdentifier || "") &&
      memo === (props.leadTimeObject.memo || "") &&
      customerCode === (props.leadTimeObject.customerCode || "")
    ) {
      // since there are no changes, safely exit.
      dontSaveChanges();
    } else {
      // With unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // save changes.
  function saveChanges() {
    if (props.saveQueryMode === "save") {
      createSavedEstimate();
    } else {
      editSavedEstimate();
    }
    setShowConfirmExit(false);
  }

  // exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="query-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader className="lead-time-header">
          <h5 className="modal-title font-weight-bold">
            {props.saveQueryMode === "save" ? (
              <div>Save Lead Time Estimate</div>
            ) : (
              <span>Edit Saved Lead Time Estimate</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <SaveEstimateForm
            memo={memo}
            orderIdentifier={orderIdentifier}
            customerCode={customerCode}
            customerCodes={customerCodes}
            onChangeQueryMemo={(memo) => setMemo(memo)}
            onChangeOrderIdentifier={(orderIdentifier) =>
              setOrderIdentifier(orderIdentifier)
            }
            onChangeCustomerCode={(customerCode) =>
              setCustomerCode(customerCode)
            }
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.saveQueryMode === "save" ? (
            <Fragment>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => createSavedEstimate()}
              >
                Save Estimate
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                className="btn btn-danger me-auto"
                type="button"
                onClick={() => setShowConfirmDelete(true)}
              >
                Delete
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => editSavedEstimate()}
              >
                Save Changes
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Delete Estimate?"
        content={
          "Are you sure that you want to delete this estimate? This action cannot be undone."
        }
        yesText="Delete Estimate"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteSavedEstimate()}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

SaveEstimateModal.propTypes = {
  saveQueryMode: PropTypes.oneOf(["save", "edit"]),
  showModal: PropTypes.bool.isRequired,
  leadTimeObject: PropTypes.object,
  itemIdentifier: PropTypes.string,
  requestTimeUtc: PropTypes.string,
  requestedBy: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
