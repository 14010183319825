/*
Author:      Dimitra Weinstein
Created:     8/4/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { formatTime } from "../../../../utilities/formatTime";
import "./EstimateHistoryTableRow.scss";

export default function HistoryTableRow(props) {
  const history = useHistory();

  // Go to the detailed history page of this estimate or highlight the estimate based on the current mode.
  function selectEstimate(estimate, mode) {
    if (mode === "search") {
      history.push(
        `/estimate-detail/${props.leadTimeHistory.itemIdentifier}/${props.leadTimeHistory.requestTimeUtc}/1/${props.leadTimeHistory.itemIdentifier}`
      );
    } else {
      props.onSelect(
        `${props.leadTimeHistory.itemIdentifier}_${props.leadTimeHistory.requestTimeUtc}`
      );
    }
  }

  // Check if the current estimate is selected.
  function checkSelected() {
    return props.estimateSelections.includes(
      `${props.leadTimeHistory.itemIdentifier}_${props.leadTimeHistory.requestTimeUtc}`
    );
  }

  return (
    <tr
      className={
        checkSelected()
          ? "estimate-history-row selected"
          : "estimate-history-row"
      }
      onClick={() => selectEstimate(props.leadTimeHistory, props.mode)}
    >
      <td>{formatTime(`${props.leadTimeHistory.requestTimeUtc}`)}</td>
      <td>{props.leadTimeHistory.itemIdentifier}</td>
      <td>{props.leadTimeHistory.requestedBy}</td>
      <td>{props.leadTimeHistory.customerCode}</td>
      <td>{props.leadTimeHistory.memo}</td>
      <td>{props.leadTimeHistory.orderIdentifier}</td>
      <td>{props.leadTimeHistory.isSaved ? "True" : "False"}</td>
    </tr>
  );
}

HistoryTableRow.propTypes = {
  leadTimeHistory: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  estimateSelections: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};
