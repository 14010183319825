/*
Author:      Zachary Thomas
Created:     10/19/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Format ISO dates as a date with slashes (ex: 10/23/2022).
export function formatDateSlashes(isoTimestamp) {
  const isoDateString = isoTimestamp.split("T")[0];
  const dateArray = isoDateString.split("-");
  if (dateArray.length === 3) {
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  } else {
    return "unknown date";
  }
}
