/*
Author:      Dimitra Weinstein
Created:     8/15/2022
Modified:    9/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./DelayEventForm.scss";

export default function DelayEventForm(props) {
  return (
    <div className="card mx-auto my-auto">
      <div className="card-body">
        <div className="row mx-auto">
          <div className="col">
            <label
              className="row form-label font-weight-bold mb-2">
              Date
            </label>
            <input
              type="date"
              className="row form-control mb-1"
              value={props.date}
              onChange={(e) => props.onChangeDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label
              className="row form-label px-3 mb-3 font-weight-bold"
              htmlFor="severityRange"
            >
              Severity: &nbsp; {props.severity}
            </label>
            <input
              id="severityRange"
              type="range"
              className="severity-slider form-range mb-2"
              min="1"
              max="10"
              step="1.0"
              placeholder="0"
              value={props.severity}
              onChange={(e) => props.onChangeSeverity(e.target.value)}
            />
          </div>
          <div className="col mx-1">
            <label
              className="row form-label px-3 mb-2 font-weight-bold">
              Category
            </label>
            <select
              name="delay-categories"
              className="form-select mb-1"
              value={props.category}
              onChange={(e) => props.onChangeCategory(e.target.value)}
            >
              <option value={""} disabled>Choose A Category</option>
              <option value="Available Delay">Available Delay</option>
              <option value="Customer Approval">Customer Approval</option>
              <option value="Hydraulic Test">Hydraulic Test</option>
              <option value="Performance Test">Performance Test</option>
              <option value="Sandblast">Sandblast</option>
              <option value="Due Date">Due Date</option>
              <option value="Impeller">Impeller</option>
              <option value="Manufacturing Time">Manufacturing Time</option>
            </select>
          </div>
          <div className="col mx-1 mb-1">
            <label
              className="row form-label px-3 mb-2 font-weight-bold">
              Memo
            </label>
            <textarea
              type="textarea"
              multiline="true"
              rows={1}
              className="px-1 form-control"
              placeholder="Memo"
              value={props.memo}
              onChange={(e) => props.onChangeMemo(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DelayEventForm.propTypes = {
  delayIdentifier: PropTypes.string,
  date: PropTypes.string,
  severity: PropTypes.string,
  category: PropTypes.string,
  memo: PropTypes.string,
  onChangeDate: PropTypes.func.isRequired,
  onChangeSeverity: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  onChangeMemo: PropTypes.func.isRequired
};