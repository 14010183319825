/*
Author:      Dimitra Weinstein
Created:     8/9/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import Spinner from "../../components/Spinner/Spinner";
import SurveyQuestion from "./SurveyQuestion/SurveyQuestion";
import apiRequest from "../../utilities/apiRequest";
import { API } from "../../utilities/constants";
import PropTypes from "prop-types";
import "./SurveyPage.scss";

export default function SurveyPage(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [questionOne, setQuestionOne] = useState(-1);
  const [questionTwo, setQuestionTwo] = useState(-1);
  const [questionThree, setQuestionThree] = useState("");
  const [questionFour, setQuestionFour] = useState("");
  const [questionFive, setQuestionFive] = useState(-1);
  const [questionSix, setQuestionSix] = useState(-1);
  const [questionSeven, setQuestionSeven] = useState(-1);
  const [questionEight, setQuestionEight] = useState(-1);
  const [questionNine, setQuestionNine] = useState(-1);
  const [questionTen, setQuestionTen] = useState(-1);
  const [questionEleven, setQuestionEleven] = useState(-1);

  // Check that the survey answers are valid.
  function surveyIsValid() {
    if (questionOne === -1) {
      setErrorMessage("Question one was not answered.");
      return false;
    } else if (questionTwo === -1) {
      setErrorMessage("Question two was not answered.");
      return false;
    } else if (questionThree === "") {
      setErrorMessage("Question three was not answered.");
      return false;
    } else if (questionFour === "") {
      setErrorMessage("Question four was not answered.");
      return false;
    } else if (questionFive === -1) {
      setErrorMessage("Question five was not answered.");
      return false;
    } else if (questionSix === -1) {
      setErrorMessage("Question six was not answered.");
      return false;
    } else if (questionSeven === -1) {
      setErrorMessage("Question seven was not answered.");
      return false;
    } else if (questionEight === -1) {
      setErrorMessage("Question eight was not answered.");
      return false;
    } else if (questionNine === -1) {
      setErrorMessage("Question nine was not answered.");
      return false;
    } else if (questionNine === 1 && questionTen === -1) {
      setErrorMessage("Question ten was not answered.");
      return false;
    } else if (questionNine === 1 && questionEleven === -1) {
      setErrorMessage("Question eleven was not answered.");
      return false;
    } else {
      return true;
    }
  }

  async function submitSurvey(identifier) {
    if (!surveyIsValid()) {
      return;
    }

    setLoading(true);
    const apiUrl = `${API}/survey/${identifier.split("_")[0]}`;
    const updatedQuery = {
      surveyResult: {
        isOrder: !!questionOne,
        onTime: !!questionTwo,
        quotedFulfillment: `${questionThree}T00:00:00`,
        fulfillmentDate: `${questionFour}T00:00:00`,
        miscalcOnHand: !!questionFive,
        miscalcDemand: !!questionSix,
        miscalcAvailableQty: !!questionSeven,
        miscalcsupplierArrivalDate: !!questionEight,
        miscalcManufacturingTime: !!questionNine,
        miscalcEpicor: questionNine === 1 ? !!questionTen : false,
        unforeseenEvent: questionNine === 1 ? !!questionEleven : false,
      },
      requestTimeUtc: identifier.split("_")[1],
    };

    const [response, responseBody] = await apiRequest(
      apiUrl,
      "PUT",
      updatedQuery
    );
    setLoading(false);

    if (response.ok && responseBody) {
      setErrorMessage("");
      setSuccessMessage("Survey submitted. Thank you for your feedback.");
    } else {
      setSuccessMessage("");
      setErrorMessage("Internal server error. Unable to submit survey.");
    }
  }

  return (
    <div className="survey-page mx-auto">
      <Spinner loading={loading} />
      <div className="accordion accordion-flush" id="surveyAccordion">
        <div className="border row col-12 mx-auto mb-2 accordion-item">
          <div
            style={{ cursor: "pointer", height: "50px" }}
            className="text-white accordion-header component-part-list accordion-button collapsed"
            id="accordion-heading"
            accordion-icon-color="white"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <div className="card-header lead-time-header">
              Report Lead Time Accuracy
            </div>
          </div>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#surveyAccordion"
          >
            <div className="accordion-body row">
              <div className="row">
                <div className="mx-5">
                  <SurveyQuestion
                    question="1. Was this lead time query attached to an order?"
                    checkedValue={questionOne}
                    onChange={(checkedValue) => setQuestionOne(checkedValue)}
                  />

                  <SurveyQuestion
                    question="2. Did the order arrive on time?"
                    checkedValue={questionTwo}
                    onChange={(checkedValue) => setQuestionTwo(checkedValue)}
                  />

                  <div className="row mt-5">
                    <div className="col-3" />
                    <div className="col-4 font-weight-bold">
                      3. What day did we tell the customer the order would be
                      ready?
                    </div>
                    <div className="col-2">
                      <input
                        className="form-check-inline mx-2 px-2"
                        type="date"
                        value={questionThree}
                        onChange={(e) => setQuestionThree(e.target.value)}
                      />
                    </div>
                    <div className="col-3" />
                  </div>

                  <div className="row mt-5">
                    <div className="col-3" />
                    <div className="col-4 font-weight-bold">
                      4. What day was the order fulfilled?
                    </div>
                    <div className="col-2">
                      <input
                        className="form-check-inline mx-2 px-2"
                        type="date"
                        value={questionFour}
                        onChange={(e) => setQuestionFour(e.target.value)}
                      />
                    </div>
                    <div className="col-3" />
                  </div>

                  <SurveyQuestion
                    question="5. Was there a miscalculation of the quantity of parts on hand?"
                    checkedValue={questionFive}
                    onChange={(checkedValue) => setQuestionFive(checkedValue)}
                  />

                  <SurveyQuestion
                    question="6. Was there a miscalculation with demand?"
                    checkedValue={questionSix}
                    onChange={(checkedValue) => setQuestionSix(checkedValue)}
                  />

                  <SurveyQuestion
                    question="7. Was there a miscalculation in available quantity?"
                    checkedValue={questionSeven}
                    onChange={(checkedValue) => setQuestionSeven(checkedValue)}
                  />

                  <SurveyQuestion
                    question="8. Did we underestimate our supplier arrival date?"
                    checkedValue={questionEight}
                    onChange={(checkedValue) => setQuestionEight(checkedValue)}
                  />

                  <SurveyQuestion
                    question="9. Was there a manufacturing time miscalculation?"
                    checkedValue={questionNine}
                    onChange={(checkedValue) => setQuestionNine(checkedValue)}
                  />

                  {questionNine === 1 && (
                    <SurveyQuestion
                      question="10. Were the manufacturing time miscalculations due to underestimations in Epicor?"
                      checkedValue={questionTen}
                      onChange={(checkedValue) => setQuestionTen(checkedValue)}
                    />
                  )}

                  {questionNine === 1 && (
                    <SurveyQuestion
                      question="11. Was there an unforeseen event (machine breakdowns, natural event, etc.) that resulted in a manufacturing time miscalculation?"
                      checkedValue={questionEleven}
                      onChange={(checkedValue) =>
                        setQuestionEleven(checkedValue)
                      }
                    />
                  )}

                  <div className="row align-items-center">
                    <div className="col-4 offset-4 text-center">
                      {successMessage.length === 0 && (
                        <button
                          type="submit"
                          className="btn btn-lg btn-outline-success mt-5 mb-3"
                          onClick={() => submitSurvey(props.identifier)}
                        >
                          Submit
                        </button>
                      )}

                      {errorMessage.length > 0 && (
                        <div className="row">
                          <div className="col mt-4 mx-2">
                            <Error message={errorMessage} />
                          </div>
                        </div>
                      )}

                      {successMessage.length > 0 && (
                        <div className="row">
                          <div className="col mt-4 mx-2">
                            <Success message={successMessage} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SurveyPage.propTypes = {
  identifier: PropTypes.string.isRequired,
};
