/*
Author:      Dimitra Weinstein
Created:     7/19/2022
Modified:    7/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import { createContext } from "react";

// Context that can be used throughout the application.
const Context = createContext(null);
export default Context;