/*
Author:      Zachary Thomas
Created:     10/11/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { formatDateSlashes } from "../../../../utilities/formatDateSlashes";
import "./PrinterSafePartListRow.css";

// A child part in a printed version of the estimate's part list.
export default function PrinterSafePartListRow(props) {
  const [indentationString, setIndentationString] = useState("0rem");

  // Get custom indentation offset.
  useEffect(() => {
    setIndentationString(`${props.indentationLevel * 3}rem`);
  }, [props.indentationLevel]);

  return (
    <Fragment>
      <div style={{ marginLeft: indentationString }}>
        <span>|{props.item.itemIdentifier},</span>

        <span>&nbsp;{props.item.description},</span>

        <span>
          &nbsp;{props.item.quotedLeadTime} days (
          {formatDateSlashes(props.item.fulfillmentDate)})|
        </span>
      </div>
      {props.item.estimateList.map((item, i) => (
        <PrinterSafePartListRow
          key={`${props.indentationLevel}_${i}`}
          item={item}
          indentationLevel={props.indentationLevel + 1}
        />
      ))}
    </Fragment>
  );
}

PrinterSafePartListRow.propTypes = {
  item: PropTypes.object.isRequired,
  indentationLevel: PropTypes.number.isRequired,
};
