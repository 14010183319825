/*
Author:      Dimitra Weinstein
Created:     8/12/2022
Modified:    10/17/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useReducer, useState, useContext } from "react";
import deepCopy from "../../utilities/deepCopy";
import DelayEventTable from "./DelayEventTable/DelayEventTable";
import DelayEventModal from "./DelayEventTable/DelayEventTableRow/DelayEventModal/DelayEventModal";
import changeKeyName from "../../utilities/changeKeyName";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import useApi from "../../hooks/useApi";
import Context from "../../components/Context/Context";
import { API } from "../../utilities/constants";

export default function DelayEventsPage() {
  const types = {
    SORT_EVENTS: "SORT_EVENTS",
    SET_EVENTS: "SET_EVENTS",
    CREATE_EVENT: "CREATE_EVENT",
    UPDATE_EVENT: "UPDATE_EVENT",
    DELETE_EVENT: "DELETE_EVENT"
  };

  const initialEvent = {
    identifier: "",
    date: "",
    severity: "1",
    category: "",
    memo: ""
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage] = useState("");
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedEvent, setSelectedEvent] = useState(initialEvent);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumnIndex, setSortColumnIndex] = useState(1);
  const [events, dispatch] = useReducer(eventReducer, []);
  const context = useContext(Context);

  // Make Delay Item API Request.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/delayitem`,
      authorization: localStorage.getItem("token")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        const items = responseBody.Items;
        items.forEach(item => changeKeyName(item, "partNumber-delayItem-config", "identifier"));
        dispatch({ type: "SET_EVENTS", payload: items });
      }
      setLoading(false);
    },
    []
  );

  // Set the operations theme.
  useEffect(() => {
    context.setTheme("operations");
  }, []);

  // If the selected Index changes, update the selected event.
  useEffect(() => {
    const index = events.findIndex(event =>
      event.identifier === selectedId
    );

    if (index === -1) {
      setSelectedEvent(initialEvent);
    } else {
      setSelectedEvent(events[index]);
    }
  }, [selectedId, JSON.stringify(events)]);

  // Event Reducer.
  function eventReducer(state, action) {
    switch (action.type) {
      case types.SORT_EVENTS: {
        let stateDeepCopy = deepCopy(state);
        stateDeepCopy = sortEvents(stateDeepCopy);
        return stateDeepCopy;
      }

      case types.SET_EVENTS: {
        action.payload = sortEvents(action.payload);
        return action.payload;
      }

      case types.CREATE_EVENT: {
        let stateShallowCopy = [...state, action.payload];
        stateShallowCopy = sortEvents(stateShallowCopy);
        return stateShallowCopy;
      }

      case types.UPDATE_EVENT: {
        let stateDeepCopy = deepCopy(state);
        const eventIndex = state.findIndex(event =>
          event.identifier === action.payload.identifier
        );
        if (eventIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(eventIndex, 1, action.payload);
          stateDeepCopy = sortEvents(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_EVENT: {
        const stateDeepCopy = deepCopy(state);
        const eventIndex = state.findIndex(event =>
          event.identifier === action.payload.identifier
        );
        if (eventIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(eventIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  // When the sorting rules change, re-sort all events.
  useEffect(() => {
    dispatch({ type: "SORT_EVENTS", payload: null });
  }, [sortAscending, sortColumnIndex]);

  // Sort events.
  function sortEvents(events) {
    return events.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = new Date(a.date);
          bValue = new Date(b.date);
          break;
        case 2:
          aValue = parseInt(a.severity, 10);
          bValue = parseInt(b.severity, 10);
          break;
        case 3:
          aValue = a.category;
          bValue = b.category;
          break;
        case 4:
          aValue = a.memo;
          bValue = b.memo;
          break;
        default:
          aValue = a.date;
          bValue = b.date;
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  return (
    <div>
      <Spinner loading={loading} />
      <div className="pt-4">
        <DelayEventTable
          events={events}
          sortAscending={sortAscending}
          sortColumnIndex={sortColumnIndex}
          onSelect={selectedId => setSelectedId(selectedId)}
          onChangeSortAscending={(sortAscending) => setSortAscending(sortAscending)}
          onChangeSortColumnIndex={(sortColumnIndex) => setSortColumnIndex(sortColumnIndex)}
        />

        <DelayEventModal
          mode={selectedId === 0 ? "create" : "edit"}
          showModal={selectedId !== -1}
          selectedEvent={selectedEvent}
          onClose={() => setSelectedId(-1)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />
      </div>
      <div className="pb-4">
        <Error message={errorMessage} />
      </div>
    </div>
  );
}