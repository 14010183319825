/*
Author:      Zachary Thomas
Created:     9/1/2022
Modified:    9/1/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert ISO 8601 string to a date object.
export default function isoToDate(iso) {
  const date = new Date(iso);
  return date;
}
