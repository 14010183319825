/*
Author:      Zachary Thomas
Created:     10/7/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDateSlashes } from "../../../../utilities/formatDateSlashes";
import "./EstimateListRow.css";

// An individual row in an estimate list.
export default function EstimateListRow(props) {
  const history = useHistory();

  return (
    <div className="part-list-button list-group mb-1">
      <div className="row mx-1 justify-content-center">
        <button
          type="button"
          className="row list-group-item list-group-item-action"
          onClick={() =>
            history.push(
              `/estimate-calculator/${props.item.itemIdentifier}/${props.item.requestTimeUtc}/1/${props.item.itemIdentifier}`
            )
          }
        >
          <div className="row justify-content-center">
            <h5 className="col-6 my-auto">{props.item.itemIdentifier}</h5>
            <p className="col-6 my-auto">
              Estimated lead time:&nbsp;
              <b>
                {props.item.quotedLeadTime} days (
                {formatDateSlashes(props.item.fulfillmentDate)})
              </b>
            </p>
          </div>
        </button>
      </div>
    </div>
  );
}

EstimateListRow.propTypes = {
  item: PropTypes.object.isRequired,
};
