/*
Author:      Grayson Fleming
Created:     7/30/2021
Modified:    10/19/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import { withRouter } from "react-router-dom";
import {
  API,
  BASIC_USER_ROLE,
  OPERATIONS_ROLE,
} from "../../utilities/constants";
import Context from "../../components/Context/Context";
import { camelCaseToTitleCase } from "../../utilities/camelCaseToTitleCase";
import Spinner from "../../components/Spinner/Spinner";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import apiRequest from "../../utilities/apiRequest";
import Info from "./Info/Info";
import deepCopy from "../../utilities/deepCopy";
import "./ConfigurationPage.css";

function ConfigurationsPage() {
  const [configurationData, setConfigurationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const context = useContext(Context);

  // Set the admin theme.
  useEffect(() => {
    context.setTheme("admin");
  }, []);

  // Get configurations from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/configuration`,
      authorization: localStorage.getItem("token"),
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        const fieldNames = {};
        const keyMap = responseBody.keyMap;
        const globalConfigs = [];
        Object.keys(responseBody.configuration).forEach(
          (key) => (fieldNames[key] = responseBody.configuration[key])
        );
        Object.keys(fieldNames).forEach((fieldName) => {
          const newGlobalConfig = {
            keyName: fieldName,
            description: "",
            value: -1,
          };
          if (keyMap[fieldName] && fieldNames[fieldName] !== undefined) {
            newGlobalConfig.name = keyMap[fieldName].name;
            newGlobalConfig.description = keyMap[fieldName].description;
            newGlobalConfig.value = fieldNames[fieldName];
          }
          globalConfigs.push(newGlobalConfig);
        });
        setConfigurationData(globalConfigs);
      } else {
        if (response.status >= 500) {
          setErrorMessage(
            "Internal server error. Unable to get configuration data."
          );
        }
      }
      setLoading(false);
    },
    []
  );

  // Update API configurations.
  async function putConfigurations(configurationData) {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    const apiUrl = `${API}/configuration`;

    // Construct new request body from array of key value pairs.
    const requestBody = {};
    configurationData.forEach(
      (configuration) =>
        (requestBody[configuration.keyName] = configuration["value"])
    );
    const [response, responseBody] = await apiRequest(
      apiUrl,
      "PUT",
      requestBody
    );

    if (response.ok) {
      setSuccessMessage("Successfully updated global configuration");
    } else {
      if (response.status >= 500 || responseBody.error === undefined) {
        setErrorMessage(
          "Internal server error. Unable to update configuration data."
        );
      } else {
        setErrorMessage(responseBody.error);
      }
    }
    setLoading(false);
  }

  // Update the current key's value.
  function updateValue(key, value) {
    const configurationDataDeepCopy = deepCopy(configurationData);
    const index = configurationDataDeepCopy.findIndex(
      (record) => key === record.keyName
    );
    if (index >= 0) {
      if (isNaN(value) || value.length === 0 || parseInt(value) < 0) {
        configurationDataDeepCopy[index].value = 0;
      } else {
        configurationDataDeepCopy[index].value = parseInt(value);
      }
      setConfigurationData(configurationDataDeepCopy);
    }
  }
  return (
    <div className="py-4">
      <Spinner loading={loading} />
      <div className="global-config-page card mx-auto">
        <div className="card-header text-white configuration-header">
          Global Configuration
        </div>
        <div className="card-body config-form-body">
          <div className="form-group row">
            <div className="text-center">
              {/* Global Value Fields */}
              {configurationData.map((data) => (
                <div
                  key={data.keyName}
                  className="global-field-input mx-auto text-start"
                >
                  <div className="configuration-row row align-items-center mx-auto">
                    <div className="col-1" />
                    <label className="col-7 font-weight-bold my-3">
                      {camelCaseToTitleCase(data.keyName)}
                      <Info
                        key={data.keyName}
                        fieldId={data.keyName}
                        fieldName={data.name}
                        toolTipText={data.description}
                      />
                      <span>:&nbsp;</span>
                    </label>
                    <div className="col-2">
                      <input
                        type="number"
                        className="form-control"
                        value={data.value}
                        readOnly={[OPERATIONS_ROLE, BASIC_USER_ROLE].includes(
                          context.role
                        )}
                        min={0}
                        max={10000}
                        onChange={(e) =>
                          updateValue(data.keyName, e.target.value)
                        }
                      />
                    </div>
                    <span className="col-1 font-weight-bold">days</span>
                    <div className="col-1" />
                  </div>
                </div>
              ))}

              {/* Save Changes Button */}
              {Object.keys(configurationData).length > 0 && (
                <button
                  className="btn btn-primary save-config-button w-50 mt-4 mb-4"
                  onClick={() => putConfigurations(configurationData)}
                >
                  Save Configuration
                </button>
              )}

              {/* Error Message */}
              <Error message={errorMessage} />

              {/* Success Message */}
              <Success message={successMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ConfigurationsPage);
