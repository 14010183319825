/*
Author:      Zachary Thomas
Created:     10/7/2022
Modified:    10/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EstimateListRow from "./EstimateListRow/EstimateListRow";
import "./EstimateList.css";

// A list of selectable estimates inside of another estimate.
export default function EstimateList(props) {
  return (
    <Fragment>
      <div className="accordion accordion-flush show mb-4 estimate-list"
        id="componentAccordion">
        <div className="accordion-item">
          <div
            style={{ cursor: "pointer", height: "50px" }}
            className="lead-time-header estimate-list text-white accordion-header accordion-button"
            id="accordion-headingTwo"
            accordion-icon-color="white"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <p className="fs-6 mx-2 my-1">
              Estimate list
            </p>
          </div>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse show"
            data-bs-parent="#componentAccordion">
            <div className="accordion-body">
              {Array.isArray(props.partsList) &&
                props.partsList.length > 0 ?
                props.partsList.map((item, i) =>
                  <EstimateListRow
                    key={i}
                    item={item}
                  />
                ) : (
                  <div className="no-estimates-text mx-auto my-5 text-center">
                    There are no estimates to display.
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

EstimateList.propTypes = {
  partsList: PropTypes.array.isRequired
};