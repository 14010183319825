/*
Author:      Dimitra Weinstein
Created:     8/15/2022
Modified:    10/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatTime } from "../../../../utilities/formatTime";
import { ENABLE_COLORED_EVENT_SEVERITY } from "../../../../utilities/constants";
import "./DelayEventTableRow.scss";

export default function DelayEventTableRow(props) {
  const [severityLevel, setSeverityLevel] = useState("high");

  // Calculate what color of severity to use.
  useEffect(() => {
    if (ENABLE_COLORED_EVENT_SEVERITY) {
      if (props.selectedEvent.severity < 4) {
        setSeverityLevel("low");
      } else if (props.selectedEvent.severity >= 4 && props.selectedEvent.severity <= 7) {
        setSeverityLevel("medium");
      } else {
        setSeverityLevel("high");
      }
    }
  }, [JSON.stringify(props.selectedEvent)]);

  return (
    <Fragment>
      <tr
        className="delay-event-row"
        onClick={() => props.onSelect(props.selectedEvent.identifier)}
      >
        <td className="px-1">
          {formatTime(`${props.selectedEvent.date}`).split(",")[0]}
        </td>
        <td className="px-1 font-weight-bold">

          <div className="row">
            <div className="col me-0 pe-0">
              <div className={`ps-2 severity-bar severity-${severityLevel}`}>
                {props.selectedEvent.severity}
              </div>
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 2 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 3 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 4 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 5 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 6 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 7 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 8 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col px-0">
              <div className={props.selectedEvent.severity >= 9 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
            <div className="col ps-0">
              <div className={props.selectedEvent.severity >= 10 ? `severity-bar severity-${severityLevel}` : `severity-${severityLevel}`} />
            </div>
          </div>

        </td>
        <td className="px-1">
          {props.selectedEvent.category}
        </td>
        <td className="px-1">
          {props.selectedEvent.memo}
        </td>
      </tr>
    </Fragment>
  );
}

DelayEventTableRow.propTypes = {
  selectedEvent: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf(["edit"]),
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  onSelect: PropTypes.func.isRequired
};
