/*
Author:      Zachary Thomas
Created:     10/4/2022
Modified:    10/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Change the key name of an object.
export default function changeKeyName(object, oldKey, newKey) {
  if (oldKey in object) {
    object[newKey] = object[oldKey];
    delete object[oldKey];
  }
}