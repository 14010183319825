/*
Author:      Zachary Thomas
Created:     10/4/2022
Modified:    10/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";

// A simple survey question with a yes or no answer.
export default function SurveyQuestion(props) {
  return (
    <div className="row mt-5">
      <div className="col-3" />

      <div className="col-4 font-weight-bold">
        {props.question}
      </div>

      <div className="col-2">
        <label
          className="form-check-label px-2 mx-auto"
          htmlFor="Radio1"
        >
          Yes
          <input
            className="form-check-input mx-2"
            type="radio"
            checked={props.checkedValue === 1}
            onChange={() => props.onChange(1)}
          />
        </label>
        <label
          className="form-check-label px-2 mx-auto"
          htmlFor="Radio2"
        >
          No
          <input
            className="form-check-input mx-2"
            type="radio"
            checked={props.checkedValue === 0}
            onChange={() => props.onChange(0)}
          />
        </label>
      </div>

      <div className="col-3" />
    </div>
  );
}

SurveyQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  checkedValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

