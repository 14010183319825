/*
Author:      Dimitra Weinstein
Created:     8/25/2022
Modified:    10/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import { formatTime } from "../../../../utilities/formatTime";
import "../ProductionSchedule.css";

export default function ProductionScheduleTableRow(props) {
  return (
    <tr>
      <td>{formatTime(props.timePhaseData.PartDtl_DueDate).split(",")[0]}</td>
      <td>{props.timePhaseData.PartDtl_PartNum}</td>
      <td>{parseFloat(props.timePhaseData.PartDtl_Quantity).toFixed(2)}</td>
      <td>{parseFloat(props.timePhaseData.Calculated_demand).toFixed(2)}</td>
      <td>{parseFloat(props.timePhaseData.Calculated_supply).toFixed(2)}</td>
      <td>{props.timePhaseData.Calculated_daysToFulfill}</td>
      <td>{props.timePhaseData.Calculated_Explanation}</td>
      <td>{props.timePhaseData.PartDtl_JobNum}</td>
      <td>{props.timePhaseData.PartDtl_OrderNum}</td>
      <td>{props.timePhaseData.PartDtl_PONum}</td>
      <td>{parseFloat(props.timePhaseData.Calculated_TransactionQty).toFixed(2)}</td>
      <td>{parseFloat(props.timePhaseData.Calculated_runningBalance).toFixed(2)}</td>
    </tr>
  );
}

ProductionScheduleTableRow.propTypes = {
  timePhaseData: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func
};
