/*
Author:      Zachary Thomas
Created:     10/5/2022
Modified:    10/5/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Get the current time in ISO8601 format, while also supply micro second precision.
export default function nowIsoMicro() {
  const currentDate = new Date();

  const performanceTime = performance.now().toString();
  let microSeconds = "";
  if (performanceTime.split(".").length === 2 && performanceTime.split(".")[1].length >= 3) {
    // Get microsecond data.
    microSeconds += performanceTime.split(".")[1][0];
    microSeconds += performanceTime.split(".")[1][1];
    microSeconds += performanceTime.split(".")[1][2];
  } else {
    // We can't find microsecond data so get three random digits.
    microSeconds += (Math.floor(Math.random() * 10)).toString();
    microSeconds += (Math.floor(Math.random() * 10)).toString();
    microSeconds += (Math.floor(Math.random() * 10)).toString();
  }

  return `${currentDate.toISOString().split("Z")[0]}${microSeconds}`;
}