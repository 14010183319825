/*
Author:      Zachary Thomas
Created:     10/6/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./TableSortedHeaderColumn.scss";

// A clickable header column for sorting table results.
export default function TableSortedHeaderColumn(props) {
  return (
    <th
      className={`sorted-header-column ${props.lightText ? "light-text" : ""}`}
      onClick={() => props.onClick(props.index)}
    >
      <span
        className={
          props.selectedIndex === props.index
            ? "sorted-header-selected"
            : "sorted-header-unselected"
        }
      >
        {props.name}
      </span>

      <span className="float-end">
        {props.selectedIndex === props.index ? (
          <Fragment>
            {props.selectedAscending ? (
              <span className="sorted-header-selected">&#9650;</span>
            ) : (
              <span className="sorted-header-selected">&#9660;</span>
            )}
          </Fragment>
        ) : (
          <span className="sorted-header-unselected">&#9660;</span>
        )}
      </span>
    </th>
  );
}

TableSortedHeaderColumn.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedAscending: PropTypes.bool.isRequired,
  lightText: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
