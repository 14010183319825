/*
Author:      Zachary Thomas
Created:     8/6/2021
Modified:    9/19/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import "./Info.css";

// Info icon with tooltip.
function Info(props) {
  return (
    <div className="info-container">
      <button className="info-button btn ps-1 pe-0" data-tip data-for={`tt-${props.fieldId}`}>
        <i className="fa fa-info-circle" />
      </button>
      <ReactTooltip id={`tt-${props.fieldId}`} place="right" effect="solid">
        {props.toolTipText}
      </ReactTooltip>
    </div>
  );
}

export default Info;

Info.propTypes = {
  fieldId: PropTypes.any,
  fieldName: PropTypes.string,
  toolTipText: PropTypes.string
};