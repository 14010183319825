/*
Author:      Dimitra Weinstein
Created:     7/25/2022
Modified:    7/25/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Returns a date string by taking in an offset
export default function getDateByOffset(offset) {
  const date = new Date();
  date.setDate(date.getDate() - offset);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
}