/*
Author:      Zachary Thomas
Created:     10/26/2022
Modified:    10/26/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert UTC timestamp, ISO 8601 string, or date object to short human readable local time.
export default function formatDateShortLocal(timestamp) {
  return new Date(Date.parse(timestamp)).toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
}
