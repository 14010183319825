/*
Author:      Dimitra Weinstein
Created:     9/9/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDateSlashes } from "../../../../utilities/formatDateSlashes";
import "./ComponentPartListRow.css";

// A child part in an estimate's part list.
export default function ComponentPartListRow(props) {
  const { topItemIdentifier, requestTimeUtc } = useParams();
  const [queryUrl, setQueryUrl] = useState("");
  const [indentationString, setIndentationString] = useState("0rem");
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  // Get custom indentation offset.
  useEffect(() => {
    setIndentationString(`${props.indentationLevel * 3}rem`);
  }, [props.indentationLevel]);

  // Create a URL.
  useEffect(() => {
    setQueryUrl(
      `/estimate-calculator/${topItemIdentifier}/${requestTimeUtc}/${
        props.item.requiredQty || 0
      }/${props.path}`
    );
  }, [
    props.path,
    topItemIdentifier,
    requestTimeUtc,
    JSON.stringify(props.item),
  ]);

  return (
    <Fragment>
      <div
        className="part-list-row py-2 my-2"
        style={{ marginLeft: indentationString }}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className="row align-items-center">
          <div className="col-1 text-center">
            {props.item.estimateList.length > 0 && expanded && (
              <span>&#9660;</span>
            )}
            {props.item.estimateList.length > 0 && !expanded && (
              <span>&#9654;</span>
            )}
          </div>
          <div className="col-3">
            Part number: <b>{props.item.itemIdentifier}</b>
          </div>

          <div className="col-3">
            Description: <b>{props.item.description}</b>
          </div>

          <div className="col-3">
            Estimated lead time:&nbsp;
            <b>
              {props.item.quotedLeadTime} days (
              {formatDateSlashes(props.item.fulfillmentDate)})
            </b>
          </div>
          <div className="col-2 text-center">
            <button
              type="button"
              className="btn btn-primary w-75"
              onClick={() => history.push(queryUrl)}
            >
              View Estimate
            </button>
          </div>
        </div>
      </div>
      {expanded &&
        props.item.estimateList.map((item, i) => (
          <ComponentPartListRow
            key={`${props.path}_${i}`}
            item={item}
            path={`${props.path}&${item.itemIdentifier}`}
            indentationLevel={props.indentationLevel + 1}
          />
        ))}
    </Fragment>
  );
}

ComponentPartListRow.propTypes = {
  item: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  indentationLevel: PropTypes.number.isRequired,
};
