/*
Author:      Dimitra Weinstein
Created:     8/24/2022
Modified:    10/26/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState } from "react";
import {
  API,
  START_DATE,
  END_DATE,
  ROW_LIMIT,
} from "../../../utilities/constants";
import PropTypes from "prop-types";
import getDateByOffset from "../../../utilities/getDateByOffset";
import DateSelection from "../DateSelection/DateSelection";
import PaginationButtons from "../PaginationButtons/PaginationButtons";
import Spinner from "../../../components/Spinner/Spinner";
import ProductionScheduleTableRow from "./ProductionScheduleTableRow/ProductionScheduleTableRow";
import apiRequest from "../../../utilities/apiRequest";
import ProductionScheduleModal from "./ProductionScheduleTableRow/ProductionScheduleModal/ProductionScheduleModal";
import "./ProductionSchedule.css";

export default function ProductionScheduleTable(props) {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(getDateByOffset(START_DATE));
  const [endDate, setEndDate] = useState(getDateByOffset(END_DATE));
  const [pageCount, setPageCount] = useState(props.pageCount);
  const [pageNumber, setPageNumber] = useState(props.pageNumber);
  const [timePhase, setTimePhase] = useState([]);
  const [dataList] = useState(timePhase);
  const [rowLimit, setRowLimit] = useState(ROW_LIMIT);
  const [quantity, setQuantity] = useState(0);
  const [showModal, setShowModal] = useState(false);

  async function retrieveProductionSchedule(
    partIdentifier,
    pageNumber,
    startDate,
    endDate
  ) {
    const apiUrl = `${API}/productionschedule/${partIdentifier}/${pageNumber}`;
    const requestBody = {
      quantity: props.quantity,
      startdate: `${startDate}T12:00:00`,
      enddate: `${endDate}T12:00:00`,
      rowlimit: ROW_LIMIT,
    };
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      apiUrl,
      "POST",
      requestBody
    );
    setLoading(false);

    if (response.ok && responseBody) {
      setStartDate(startDate);
      setEndDate(endDate);
      setRowLimit(rowLimit);
      setQuantity(quantity);
      setPageCount(responseBody.pageCount);
      setTimePhase(responseBody.timephase);
    }
  }

  useEffect(() => {
    retrieveProductionSchedule(
      props.itemIdentifier,
      pageNumber,
      startDate,
      endDate
    );
  }, [props.itemIdentifier, pageNumber, startDate, endDate]);

  function handleDateChange(startDate, endDate, pageCount, timePhase) {
    setStartDate(startDate);
    setEndDate(endDate);
    setPageCount(pageCount);
    setTimePhase(timePhase);
  }

  function handlePageChange(pageNumber, pageCount, timePhase, ROW_LIMIT) {
    setPageNumber(pageNumber);
    setPageCount(pageCount);
    setTimePhase(timePhase);
    setRowLimit(ROW_LIMIT);
  }

  return (
    <Fragment>
      <Spinner loading={loading} />

      <div className="mb-1">
        <div className="accordion accordion-flush" id="productionAccordion">
          <div className="border bg-light row col-12 mx-auto mb-2 accordion-item">
            <h2
              className="lead-time-header text-white accordion-header production-schedule"
              id="accordion-heading"
              accordion-icon-color="white"
            >
              <div className="d-flex align-items-center position-relative">
                <button
                  className={`accordion-button collapsed fs-6 mx-2 px-2 my-1 lead-time-header text-white col production-schedule`}
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Production Schedule
                </button>
                {timePhase.length > 0 && (
                  <button
                    style={{ width: "300px" }}
                    className="btn btn-sm save-query-button btn-success modal-button col"
                    onClick={() => setShowModal(true)}
                  >
                    <strong>View Supply & Demand Graph</strong>
                  </button>
                )}
              </div>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse show production-schedule-body"
              data-bs-parent="#productionAccordion"
            >
              <div className="accordion-body row mx-auto">
                <Fragment>
                  <div className="col-7 col-xxl-4 mx-auto">
                    <DateSelection
                      startDate={startDate}
                      endDate={endDate}
                      onStartDateChange={(startDate) =>
                        handleDateChange(
                          startDate,
                          endDate,
                          pageCount,
                          timePhase
                        )
                      }
                      onEndDateChange={(endDate) =>
                        handleDateChange(
                          startDate,
                          endDate,
                          pageCount,
                          timePhase
                        )
                      }
                    />
                  </div>
                  <div className="col-4 col-xxl-7 mb-2 mt-1">
                    {timePhase.length > 0 && (
                      <PaginationButtons
                        pageNumber={pageNumber}
                        pageCount={pageCount}
                        timePhaseData={timePhase}
                        onPageChange={(pageNumber) =>
                          handlePageChange(
                            pageNumber,
                            pageCount,
                            timePhase,
                            ROW_LIMIT
                          )
                        }
                      />
                    )}
                  </div>
                  {timePhase.length > 0 ? (
                    <div className="timephase-table">
                      <table className="table table-bordered table-responsive table-hover table-striped">
                        <thead className="production-schedule text-white">
                          <tr>
                            <th>Due Date</th>
                            <th>Part Number</th>
                            <th>Quantity</th>
                            <th>Demand</th>
                            <th>Supply</th>
                            <th>Days To Fulfill</th>
                            <th>Explanation</th>
                            <th>Job Number</th>
                            <th>Order Number</th>
                            <th>PO Number</th>
                            <th>Transaction Qty</th>
                            <th>Running Balance</th>
                          </tr>
                        </thead>

                        {timePhase.map((dataItem, i) => (
                          <tbody key={i}>
                            <ProductionScheduleTableRow
                              key={i}
                              timePhaseData={dataItem}
                              startDate={startDate}
                              endDate={endDate}
                              dataList={dataList}
                            />
                          </tbody>
                        ))}
                      </table>
                    </div>
                  ) : (
                    <div className="no-timephase-text mx-auto my-5 text-center">
                      <span>
                        There is no production schedule data to display.
                      </span>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductionScheduleModal
        showModal={showModal}
        productionSchedule={timePhase}
        onClose={() => setShowModal(false)}
      />
    </Fragment>
  );
}

ProductionScheduleTable.propTypes = {
  itemIdentifier: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
