/*
Author:      Grayson Fleming
Created:     7/28/2021
Modified:    10/27/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import "dotenv/config";

let api = "https://sblj6pepsj.execute-api.us-west-2.amazonaws.com/dev";
if (process.env.REACT_APP_ENV === "production") {
  api = "https://0xej7x1j1l.execute-api.us-west-2.amazonaws.com/prod";
}

// Constants that are referenced throughout the application.
export const USER_POOL = {
  UserPoolId: "us-west-2_x5RZoNGJG",
  ClientId: "4tms824bvce4rs55l5mrrrl5vv",
};
export const API = api;
export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = 60 * 1000;
export const MS_PER_HOUR = 60 * 60 * 1000;
export const MS_PER_DAY = 24 * 60 * 60 * 1000;
export const MS_PER_MONTH = 30 * 24 * 60 * 60 * 1000;
export const MS_PER_YEAR = 365 * 24 * 60 * 60 * 1000;
export const ADMIN_ROLE = "ADMIN";
export const OPERATIONS_ROLE = "OPERATIONS";
export const BASIC_USER_ROLE = "USER";
export const END_DATE = "-30";
export const START_DATE = "0";
export const ROW_LIMIT = "25";
export const DEFAULT_PART_QTY = "1";
export const MILLISECONDS_BETWEEN_POLLING = 5000;
export const ENABLE_COLORED_EVENT_SEVERITY = false;
export const ENABLE_COMBINED_ESTIMATES = false;
export const INVALID_INFO_FIELD_KEYS = [
  "partNumber-delayItem-config",
  "estimateList",
  "weight",
  "parentPart",
  "itemIdentifier",
  "partDescription",
  "timetolive",
  "fulfillmentDate",
  "isSellable",
  "configurationsApplied",
  "quantity",
  "unit",
  "topPart",
  "requestTimeUtc",
  "lastUpdatedUtc",
  "memo",
  "survey",
  "isSaved",
  "estimateType",
  "updatedBy",
  "epicorDate",
  "question1",
  "question2",
  "question3",
  "question4",
  "question5",
  "question6",
  "question7",
  "question8",
  "question9",
  "question10",
];
