/*
Author:      Dimitra Weinstein
Created:     8/29/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import formatDateShortLocal from "../../../../../../utilities/formatDateShortLocal";
import PropTypes from "prop-types";

// Line chart for viewing time phase data
export default function SupplyAndDemandBarChart(props) {
  const [options, setOptions] = useState(null);

  // Update options.
  useEffect(() => {
    if (props.series !== null && props.series.length > 0) {
      setOptions({
        series: props.series,
        chart: {
          type: "bar",
          height: 400,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [6, 6, 6],
        },
        xaxis: {
          type: "datetime",
          tickAmount: 12,
          labels: {
            datetimeUTC: true,
            rotate: -15,
            rotateAlways: true,
            formatter: (value) => {
              const date = new Date(value);
              return formatDateShortLocal(
                date.toISOString().split(".")[0].concat(".000Z")
              );
            },
          },
        },
      });
    }
  }, [JSON.stringify(props.series)]);

  return options ? (
    <Chart
      options={options}
      series={props.series === null ? [] : props.series}
      type="line"
      height={400}
    />
  ) : null;
}

SupplyAndDemandBarChart.propTypes = {
  series: PropTypes.array,
};
