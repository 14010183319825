/*
Author:      Zachary Thomas
Created:     6/21/2021
Modified:    10/6/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext } from "react";
import Context from "../../components/Context/Context";
import "./Footer.scss";

// General footer.
function Footer() {
  const context = useContext(Context);
  const date = new Date();

  return (
    <footer
      className={
        `py-1 px-3 footer-custom`
        + ` ${context.theme === "basic" ? "footer-basic" : ""}`
        + ` ${context.theme === "operations" ? "footer-operations" : ""}`
        + ` ${context.theme === "admin" ? "footer-admin" : ""}`
      }>
      &#xA9; {date.getYear() + 1900}, Cornell Pump Company. All rights reserved.
    </footer>
  );
}

export default Footer;