/*
Author:      Grayson Fleming
Created:     6/21/2021
Modified:    10/20/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import LoginPage from "./pages/LoginPage/LoginPage";
import EstimateCalculationPage from "./pages/EstimateCalculationPage/EstimateCalculationPage";
import ConfigurationPage from "./pages/ConfigurationPage/ConfigurationPage";
import DelayEventPage from "./pages/DelayEventPage/DelayEventPage";
import EstimateHistoryPage from "./pages/EstimateHistoryPage/EstimateHistoryPage";
import Context from "./components/Context/Context";
import {
  ADMIN_ROLE,
  OPERATIONS_ROLE,
  BASIC_USER_ROLE,
} from "./utilities/constants";
import Error404Page from "./pages/Error404Page/Error404Page";
import "./App.css";

// Routing.
function App() {
  const currentPage = useLocation().pathname;
  const history = useHistory();
  const [role, setRole] = useState(BASIC_USER_ROLE);
  const [theme, setTheme] = useState("basic");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const savedRole = localStorage.getItem("userRole");

    if (role !== null) {
      setRole(savedRole);
    }

    if (!token || token.length === 0) {
      history.push("/");
    }
  }, []);

  return (
    <Context.Provider value={{ role, setRole, theme, setTheme }}>
      <Fragment>
        <Navbar currentPage={currentPage} />

        <main
          className={
            theme === "operations" || theme === "admin" ? "dark-mode" : ""
          }
        >
          <div className="app container">
            <Switch>
              <Route exact path="/">
                <LoginPage />
              </Route>

              {[ADMIN_ROLE].includes(role) && (
                <Route exact path="/global-configuration">
                  <ConfigurationPage />
                </Route>
              )}

              {[ADMIN_ROLE, OPERATIONS_ROLE].includes(role) && (
                <Route exact path="/delay-events">
                  <DelayEventPage />
                </Route>
              )}

              <Route exact path="/estimate-calculator">
                <EstimateCalculationPage isHistorical={false} />
              </Route>

              <Route
                exact
                path="/estimate-calculator/:topItemIdentifier/:requestTimeUtc/:quantity/:path"
              >
                <div>
                  <EstimateCalculationPage isHistorical={false} />
                </div>
              </Route>

              <Route exact path="/estimate-history">
                <EstimateHistoryPage
                  mode="search"
                  estimateSelections={[]}
                  onSelect={() => {
                    /* Do nothing. */
                  }}
                />
              </Route>

              <Route
                exact
                path="/estimate-detail/:topItemIdentifier/:requestTimeUtc/:quantity/:path"
              >
                <EstimateCalculationPage isHistorical={true} />
              </Route>

              <Route path="*">
                <Error404Page />
              </Route>
            </Switch>
          </div>
        </main>

        <Footer />
      </Fragment>
    </Context.Provider>
  );
}

export default App;
