/*
Author:      Dimitra Weinstein
Created:     8/24/2022
Modified:    10/24/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Error from "../../components/Error/Error";
import Spinner from "../../components/Spinner/Spinner";
import EstimateCalculator from "./EstimateCalculator/EstimateCalculator";
import EstimateInfoCard from "./EstimateInfoCard/EstimateInfoCard";
import ProductionScheduleTable from "./ProductionSchedule/ProductionScheduleTable";
import SaveEstimateModal from "../EstimateCalculationPage/SaveEstimateModal/SaveEstimateModal";
import ComponentPartList from "./ComponentPartList/ComponentPartList";
import EstimateList from "./EstimateList/EstimateList";
import { useParams } from "react-router-dom";
import Context from "../../components/Context/Context";
import { formatDateSlashes } from "../../utilities/formatDateSlashes";
import PrinterSafeEstimatePage from "./PrinterSafeEstimatePage/PrinterSafeEstimatePage";
import SurveyPage from "../SurveyPage/SurveyPage";
import "./EstimateCalculationPage.scss";

export default function EstimateCalculationPage(props) {
  const [loading] = useState(false);
  const [errorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [keyMap, setKeyMap] = useState({});
  const [leadTimeObject, setLeadTimeObject] = useState(null);
  const [quotedLeadTime, setQuotedLeadTime] = useState(0);
  const [partsList, setPartsList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rowLimit, setRowLimit] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [estimateType, setEstimateType] = useState("quote");
  const [requestedBy, setRequestedBy] = useState("");
  const { topItemIdentifier, quantity, requestTimeUtc } = useParams();
  const context = useContext(Context);

  // Set the basic theme.
  useEffect(() => {
    context.setTheme("basic");
  }, []);

  return (
    <Fragment>
      <div className="estimate-calculation-page">
        <Spinner loading={loading} />
        {/* Lead Time Calculator Form */}
        <EstimateCalculator
          keyMap={keyMap}
          leadTimeObject={leadTimeObject}
          quotedLeadTime={quotedLeadTime}
          startDate={startDate}
          endDate={endDate}
          rowLimit={rowLimit}
          pageNumber={pageNumber}
          pageCount={pageCount}
          onChangeKeyMap={(keyMap) => setKeyMap(keyMap)}
          onChangeLeadTimeObject={(leadTimeObject) =>
            setLeadTimeObject(leadTimeObject)
          }
          onChangeQuotedLeadTime={(quotedLeadTime) =>
            setQuotedLeadTime(quotedLeadTime)
          }
          onChangePartsList={(partsList) => setPartsList(partsList)}
          onChangeStartDate={(startDate) => setStartDate(startDate)}
          onChangeEndDate={(endDate) => setEndDate(endDate)}
          onChangeRowLimit={(rowLimit) => setRowLimit(rowLimit)}
          onChangePageNumber={(pageNumber) => setPageNumber(pageNumber)}
          onChangePageCount={(pageCount) => setPageCount(pageCount)}
          onChangeEstimateType={(estimateType) => setEstimateType(estimateType)}
          onChangeRequestedBy={(requestedBy) => setRequestedBy(requestedBy)}
        />

        {/* Lead Time Query Results Card */}
        {(leadTimeObject !== null || errorMessage.length > 0) && (
          <div>
            <div
              className={`card-header lead-time-header estimate-results text-white mt-4`}
            >
              <div className="row mx-auto">
                <div
                  className={
                    topItemIdentifier === leadTimeObject.itemIdentifier
                      ? "col-8"
                      : "col-10"
                  }
                >
                  Estimate Results
                </div>

                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-sm save-query-button btn-success font-weight-bold w-100"
                    onClick={() => print()}
                  >
                    Print Estimate
                  </button>
                </div>

                {topItemIdentifier === leadTimeObject.itemIdentifier && (
                  <div className="col-2">
                    <button
                      type="button"
                      className="btn btn-sm save-query-button btn-success font-weight-bold w-100"
                      onClick={() => setShowModal(true)}
                    >
                      {props.isHistorical ? "Edit Estimate" : "Save Estimate"}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {leadTimeObject !== null ? (
              <div>
                {/* Results Title with Quoted Lead Time and Part # */}
                <div className="card-body bg-white py-3 mb-4">
                  <div className="text-center mx-auto h2">
                    {quotedLeadTime === 1 ? (
                      <span>{quotedLeadTime} day to fulfillment</span>
                    ) : (
                      <span>{quotedLeadTime} days to fulfillment</span>
                    )}

                    {leadTimeObject.estimateType === "part" ? (
                      <Fragment>
                        {parseInt(quantity, 10) === 1 ? (
                          <span>
                            &nbsp;for 1 count of {leadTimeObject.itemIdentifier}
                          </span>
                        ) : (
                          <span>
                            &nbsp;for {quantity} counts of{" "}
                            {leadTimeObject.itemIdentifier}
                          </span>
                        )}
                      </Fragment>
                    ) : (
                      <span>
                        &nbsp;for the quote {leadTimeObject.itemIdentifier}
                      </span>
                    )}
                  </div>
                  <div className="text-center mx-auto py-3">
                    <h4>
                      Expected fulfillment date{" "}
                      {formatDateSlashes(leadTimeObject.fulfillmentDate)}
                    </h4>
                    {leadTimeObject.memo !== undefined &&
                      leadTimeObject.memo.length > 0 && (
                      <h5 className="mt-4">
                        <b>Memo:</b> {leadTimeObject.memo}
                      </h5>
                    )}
                  </div>
                </div>

                {/* Lead Time Info (Top Part) */}
                <EstimateInfoCard
                  keyMapping={keyMap}
                  leadTimeObject={leadTimeObject}
                />

                {/* Production Schedule Table */}
                <div>
                  {!props.isHistorical && estimateType === "part" && (
                    <ProductionScheduleTable
                      itemIdentifier={leadTimeObject.itemIdentifier}
                      quantity={parseInt(quantity, 10)}
                      pageNumber={pageNumber}
                      pageCount={pageCount}
                      startDate={startDate}
                      endDate={endDate}
                      rowLimit={rowLimit}
                    />
                  )}

                  <div className="mt-4">
                    <Error message={errorMessage} />
                  </div>
                </div>

                {/* Component Parts List */}
                {estimateType !== "estimate" && (
                  <ComponentPartList partsList={partsList} />
                )}

                {/* Estimate List */}
                {estimateType === "estimate" && (
                  <EstimateList partsList={partsList} />
                )}
              </div>
            ) : (
              <div className="card-body">
                <h4>{errorMessage}</h4>
              </div>
            )}
          </div>
        )}
      </div>

      {leadTimeObject !== null && (
        <Fragment>
          <PrinterSafeEstimatePage
            quantity={parseFloat(quantity)}
            quotedLeadTime={quotedLeadTime}
            leadTimeObject={leadTimeObject}
            keyMapping={keyMap}
            partsList={partsList}
          />
          <SaveEstimateModal
            saveQueryMode={props.isHistorical ? "edit" : "save"}
            showModal={showModal}
            itemIdentifier={leadTimeObject.itemIdentifier}
            requestTimeUtc={requestTimeUtc}
            leadTimeObject={leadTimeObject}
            requestedBy={requestedBy}
            onClose={() => setShowModal(false)}
            onChange={(leadTimeObject) => setLeadTimeObject(leadTimeObject)}
          />

          {props.isHistorical && (
            <SurveyPage identifier={`${topItemIdentifier}_${requestTimeUtc}`} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

EstimateCalculationPage.propTypes = {
  isHistorical: PropTypes.bool,
};
