/*
Author:      Dimitra Weinstein
Created:     8/15/2022
Modified:    10/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment, useEffect, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import ModalHeader from "../../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../../components/ModalFooter/ModalFooter";
import ConfirmModal from "../../../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../../../components/SaveChangesModal/SaveChangesModal";
import Error from "../../../../../components/Error/Error";
import Spinner from "../../../../../components/Spinner/Spinner";
import DelayEventForm from "../../../DelayEventForm/DelayEventForm";
import PropTypes from "prop-types";
import "./DelayEventModal.scss";
import { API } from "../../../../../utilities/constants";
import apiRequest from "../../../../../utilities/apiRequest";

// Modal allowing users to create, update and delete Delay Event Records.
export default function DelayEventModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [date, setDate] = useState("");
  const [severity, setSeverity] = useState("1");
  const [category, setCategory] = useState("");
  const [memo, setMemo] = useState("");

  // Update state when selected event prop changes.
  useEffect(() => {
    if (props.showModal) {
      setDate(props.selectedEvent.date.split("T")[0]);
      setSeverity(props.selectedEvent.severity);
      setCategory(props.selectedEvent.category);
      setMemo(props.selectedEvent.memo);
    }
  }, [props.showModal, JSON.stringify(props.selectedEvent)]);

  // Validate Event.
  function eventIsValid() {
    if (date === "") {
      setErrorMessage("Date is required.");
      return false;
    } else if (severity === "") {
      setErrorMessage("Severity is required.");
      return false;
    } else if (category === "") {
      setErrorMessage("Category is required.");
      return false;
    } else if (memo === "") {
      setErrorMessage("Memo is required.");
      return false;
    } else {
      return true;
    }
  }

  // Create an Event.
  async function createEvent(date, severity, category, memo) {
    if (eventIsValid()) {
      setLoading(true);
      const apiUrl = `${API}/delayitem`;

      const requestBody = {
        date: `${date}T12:00:00`,
        severity: severity,
        category: category,
        memo: memo
      };
      const [response, responseBody] = await apiRequest(
        apiUrl,
        "POST",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        const newIdentifier = responseBody.message.identifier;
        const requestTimeUtc = responseBody.message.requestTimeUtc;
        const newEvent = {
          identifier: newIdentifier,
          date: `${date}T12:00:00`,
          severity: severity,
          category: category,
          memo: memo,
          requestTimeUtc: requestTimeUtc
        };
        props.onAction("CREATE_EVENT", newEvent);
        dontSaveChanges();
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to create event.");
      }
    }
  }

  // edit an event.
  async function editEvent(identifier, date, severity, category, memo, requestTimeUtc) {
    if (eventIsValid()) {
      setLoading(true);
      const apiUrl = `${API}/delayitem/${identifier}`;

      const requestBody = {
        date: `${date}T12:00:00`,
        severity: severity,
        category: category,
        memo: memo,
        requestTimeUtc: requestTimeUtc
      };
      const [response, responseBody] = await apiRequest(
        apiUrl,
        "PUT",
        requestBody
      );
      setLoading(false);

      if (response.ok && responseBody) {
        const updatedEvent = {
          identifier: identifier,
          date: `${date}T12:00:00`,
          severity: severity,
          category: category,
          memo: memo,
          requestTimeUtc: requestTimeUtc
        };
        props.onAction("UPDATE_EVENT", updatedEvent);
        setErrorMessage("");
        props.onClose();
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to update event.");
      }
    }
  }

  // Delete an event.
  async function deleteEvent(identifier, requestTimeUtc) {
    setLoading(true);
    const apiUrl = `${API}/delayitem/${identifier}`;
    const requestBody = {
      requestTimeUtc: requestTimeUtc
    };
    const [response, responseBody] = await apiRequest(
      apiUrl,
      "DELETE",
      requestBody
    );
    setLoading(false);

    if (response.ok && responseBody) {
      setShowConfirmDelete(false);
      props.onClose();
      props.onAction("DELETE_EVENT", { identifier: identifier });
    } else {
      setErrorMessage("Internal server error. Unable to delete event.");
    }
  }

  // Exit Modal if no changes have been made. Otherwise, prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      date === props.selectedEvent.date.split("T")[0]
      && severity === props.selectedEvent.severity
      && category === props.selectedEvent.category
      && memo === props.selectedEvent.memo
    ) {
      // since there are no changes, safely exit.
      dontSaveChanges();
    } else {
      // With unsaved changes, give the user a chance tos ave them.
      setShowConfirmExit(true);
    }
  }

  // save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createEvent(date, severity, category, memo);
    } else {
      editEvent(props.selectedEvent.identifier, date, severity, category, memo, props.selectedEvent.requestTimeUtc);
    }
    setShowConfirmExit(false);
  }

  // exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setDate("");
    setSeverity("");
    setCategory("");
    setMemo("");
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="event-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="xl"
        animation
        centered
      >
        <ModalHeader className="delay-event-modal-header">
          <h5
            className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create Delay Event</span>
            ) : (
              <span>Edit Delay Event</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <DelayEventForm
            date={date}
            severity={severity}
            category={category}
            memo={memo}
            onChangeDate={date => setDate(date)}
            onChangeSeverity={severity => setSeverity(severity)}
            onChangeCategory={category => setCategory(category)}
            onChangeMemo={memo => setMemo(memo)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => createEvent(date, severity, category, memo)}
              >
                Create Event
              </button>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                className="btn btn-danger me-auto"
                type="button"
                onClick={() => setShowConfirmDelete(true)}
              >
                Delete
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => editEvent(props.selectedEvent.identifier, date, severity, category, memo, props.selectedEvent.requestTimeUtc)}
              >
                Save Changes
              </button>
              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Delete Event?"
        content={"Are you sure that you want to delete the event? This action cannot be undone."}
        yesText="Delete Event"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteEvent(props.selectedEvent.identifier, props.selectedEvent.requestTimeUtc)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

DelayEventModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired
};
