/*
Author:      Zachary Thomas
Created:     6/2/2021
Modified:    6/2/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./PageTitle.css";

// Simple page title.
function PageTitle(props) {
  return (
    <div className="page-title mx-auto mt-4 mb-5">
      {props.title}
    </div>
  );
}

export default PageTitle;

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};