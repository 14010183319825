/*
Author:      Zachary Thomas
Created:     4/1/2021
Modified:    10/6/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useContext } from "react";
import Context from "../../components/Context/Context";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import "./Error404Page.css";

// 404 error page.
function Error404Page() {
  const context = useContext(Context);

  // Set the basic theme.
  useEffect(() => {
    context.setTheme("basic");
  }, []);

  return (
    <div className="page-404 pt-5">
      <TextBlurb
        title="404"
        paragraph="Sorry! The page you are looking for was not found."
        icon="exclamation-triangle"
      />
    </div>
  );
}

export default Error404Page;