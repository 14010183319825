/*
Author:      Dimitra Weinstein
Created:     8/29/2022
Modified:    10/26/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import ModalHeader from "../../../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../../../components/ModalFooter/ModalFooter";
import Error from "../../../../../components/Error/Error";
import Spinner from "../../../../../components/Spinner/Spinner";
import PropTypes from "prop-types";
import SupplyAndDemandBarChart from "./SupplyAndDemandBarChart/SupplyAndDemandBarChart";
import isoToDate from "../../../../../utilities/isoToDate";
import "./ProductionScheduleModal.css";

// Modal allowing users to create, update and delete Delay Event Records.
export default function ProductionScheduleModal(props) {
  const [loading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [demand, setDemand] = useState([]);
  const [supply, setSupply] = useState([]);
  const [runningBalance, setRunningBalance] = useState([]);

  // Update state when selected event prop changes.
  useEffect(() => {
    const tempRunningBalanceData = [];
    const tempSupplyData = [];
    const tempDemandData = [];
    let previousBalance = null;
    let previousSupply = null;
    let previousDemand = null;
    let previousDate = null;
    props.productionSchedule.forEach((item) => {
      const currentDate = isoToDate(`${item.PartDtl_DueDate}.000Z`);
      // Store the data from the last valid date.
      if (
        previousDate !== null &&
        previousDate.getTime() !== currentDate.getTime()
      ) {
        if (previousBalance !== null) {
          tempRunningBalanceData.push([previousDate, previousBalance]);
          previousBalance = 0;
        }
        if (previousSupply !== null) {
          tempSupplyData.push([previousDate, previousSupply]);
          previousSupply = 0;
        }
        if (previousDemand !== null) {
          tempDemandData.push([previousDate, previousDemand]);
          previousDemand = 0;
        }
      }

      // If this is the first record, set the previous demand and supply to zero.
      if (previousDemand === null) {
        previousDemand = 0;
      }
      if (previousSupply === null) {
        previousSupply = 0;
      }

      // Update the current record based on the data.
      previousDemand += parseFloat(item.Calculated_demand);
      previousSupply += parseFloat(item.Calculated_supply);
      previousBalance = parseFloat(item.Calculated_runningBalance);
      previousDate = currentDate;
    });
    // Push the last of the data.
    if (previousDate !== null && previousBalance !== null) {
      tempRunningBalanceData.push([previousDate, previousBalance]);
    }
    if (previousDate !== null && previousSupply !== null) {
      tempSupplyData.push([previousDate, previousSupply]);
    }
    if (previousDate !== null && previousDemand !== null) {
      tempDemandData.push([previousDate, previousDemand]);
    }

    setRunningBalance(tempRunningBalanceData);
    setSupply(tempSupplyData);
    setDemand(tempDemandData);
  }, [JSON.stringify(props.productionSchedule)]);

  // exit modal.
  function closeGraphModal() {
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="event-modal-container">
      <Spinner loading={loading} />
      <Modal
        show={props.showModal}
        onHide={() => closeGraphModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader className="lead-time-header">
          <h5 className="modal-title font-weight-bold">Production Schedule</h5>
        </ModalHeader>

        <ModalBody>
          <SupplyAndDemandBarChart
            series={[
              {
                name: "Supply",
                type: "column",
                data: supply,
                color: "#DB0000",
              },
              {
                name: "Demand",
                type: "column",
                data: demand,
                color: "#63AF57",
              },
              {
                name: "Running Balance",
                type: "line",
                data: runningBalance,
                color: "#FEBC3B",
              },
            ]}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => closeGraphModal()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ProductionScheduleModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  productionSchedule: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
