/*
Author:      Dimitra Weinstein
Created:     8/3/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import EstimateHistoryTableRow from "../EstimateHistoryTable/EstimateHistoryTableRow/EstimateHistoryTableRow";
import TableSortedHeaderColumn from "../../../components/TableSortedHeaderColumn/TableSortedHeaderColumn";
import "../EstimateHistoryPage.css";

export default function EstimateHistoryTable(props) {
  // Update sorting order.
  function updateSort(newIndex) {
    if (newIndex === props.sortColumnIndex) {
      props.onChangeSortAscending(!props.sortAscending);
    } else {
      props.onChangeSortAscending(false);
      props.onChangeSortColumnIndex(newIndex);
    }
  }

  return (
    <table className="table table-bordered table-responsive table-striped table-hover mx-auto">
      <thead className={`estimate-history text-white`}>
        <tr>
          <TableSortedHeaderColumn
            name="Time of Request"
            index={1}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Part Identifier"
            index={2}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Requested By"
            index={3}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Customer Code"
            index={4}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Memo"
            index={5}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Order Number"
            index={6}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
          <TableSortedHeaderColumn
            name="Saved"
            index={7}
            selectedIndex={props.sortColumnIndex}
            selectedAscending={props.sortAscending}
            lightText={true}
            onClick={(index) => updateSort(index)}
          />
        </tr>
      </thead>
      <tbody>
        {props.leadTimeHistory.map((historyDataItem, i) => (
          <EstimateHistoryTableRow
            key={i}
            leadTimeHistory={historyDataItem}
            mode={props.mode}
            estimateSelections={props.estimateSelections}
            onSelect={(estimate) => props.onSelect(estimate)}
          />
        ))}
      </tbody>
    </table>
  );
}

EstimateHistoryTable.propTypes = {
  leadTimeHistory: PropTypes.arrayOf(
    PropTypes.shape({
      requestTimeUtc: PropTypes.string.isRequired,
      itemIdentifier: PropTypes.string.isRequired,
      requestedBy: PropTypes.string.isRequired,
      customerCode: PropTypes.string,
      memo: PropTypes.string,
      orderIdentifier: PropTypes.string,
    }).isRequired
  ),
  mode: PropTypes.string.isRequired,
  estimateSelections: PropTypes.array.isRequired,
  sortAscending: PropTypes.bool.isRequired,
  sortColumnIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChangeSortAscending: PropTypes.func.isRequired,
  onChangeSortColumnIndex: PropTypes.func.isRequired,
};
