/*
Author:      Zachary Thomas
Created:     4/7/2021
Modified:    9/23/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./NavbarLink.css";

// A single clickable link on a navbar.
function NavbarLink(props) {

  return (
    <li
      className={
        `nav-item ${props.targetPage === props.currentPage ? "nav-item-active" : ""} ${props.className}`
      }
    >
      <Link className={`nav-link`} to={props.targetPage}>
        <span>{props.name}</span>
        {props.targetPage === props.currentPage && (
          <span className="sr-only">(current)</span>
        )}
      </Link>
    </li>
  );
}

export default NavbarLink;

NavbarLink.propTypes = {
  name: PropTypes.string,
  targetPage: PropTypes.string,
  currentPage: PropTypes.string,
  className: PropTypes.string
};