/*
Author:      Zachary Thomas
Created:     8/5/2021
Modified:    11/16/2022

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert camelCase text to Tile Case text.
export function camelCaseToTitleCase(text) {
  if (typeof text === "string") {
    const intermediateText = text.replace(/([A-Z])/g, " $1");
    return intermediateText.charAt(0).toUpperCase() + intermediateText.slice(1);
  } else {
    return "";
  }
}
