/*
Author:      Dimitra Weinstein
Created:     9/19/2022
Modified:    10/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import "./SaveEstimateForm.css";

export default function SaveEstimateForm(props) {
  return (
    <div className="card mx-auto my-auto">
      <div className="card-body">
        <div className="row mx-auto">
          <div className="col-4 my-3 mx-auto">
            <label className="align-top mb-1">
              <b>Memo</b>
            </label>
            <textarea
              type="textarea"
              multiline="true"
              rows={1}
              className="form-control save-query-memo"
              value={props.memo}
              onChange={(e) => props.onChangeQueryMemo(e.target.value)}
            />
          </div>
          <div className="col-5 my-3 mx-auto">
            <label className="align-top mb-1">
              <b>Customer Code</b>
            </label>
            <input
              className="form-control px-1"
              type="text"
              list="datalistCompanyCodes"
              autoComplete="off"
              value={props.customerCode}
              onChange={(e) => props.onChangeCustomerCode(e.target.value)}
            />

            <datalist id="datalistCompanyCodes">
              {props.customerCodes.map((country) => (
                <option key={country} value={country} />
              ))}
            </datalist>
          </div>
          <div className="col-3 my-3 mx-auto">
            <label className="align-top mb-1">
              <b>Attach Order #</b>
            </label>
            <input
              type="text"
              className="form-control px-1"
              value={props.orderIdentifier}
              onChange={(e) => props.onChangeOrderIdentifier(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SaveEstimateForm.propTypes = {
  memo: PropTypes.string.isRequired,
  orderIdentifier: PropTypes.string.isRequired,
  customerCode: PropTypes.string.isRequired,
  customerCodes: PropTypes.array.isRequired,
  onChangeQueryMemo: PropTypes.func,
  onChangeOrderIdentifier: PropTypes.func,
  onChangeCustomerCode: PropTypes.func,
};
