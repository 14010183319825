/*
Author:      Grayson Fleming
Created:     6/21/2021
Modified:    10/27/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { ADMIN_ROLE, OPERATIONS_ROLE } from "../../utilities/constants";
import NavbarLink from "./NavbarLink/NavbarLink";
import SignOutButton from "./SignOutButton/SignOutButton";
import Context from "../Context/Context";
import "./Navbar.scss";

// General navbar.
function Navbar(props) {
  const context = useContext(Context);

  return (
    <nav
      className={
        `navbar navbar-expand navbar-dark navbar-custom` +
        ` ${context.theme === "basic" ? "navbar-basic" : ""}` +
        ` ${context.theme === "operations" ? "navbar-operations" : ""}` +
        ` ${context.theme === "admin" ? "navbar-admin" : ""}`
      }
    >
      <div className="navbar-brand p-2">Cornell Pump</div>

      <div
        className="collapse navbar-collapse pt-1"
        id="navbar-supported-content"
      >
        {props.currentPage !== "/" && (
          <Fragment>
            <ul className="navbar-nav me-auto">
              <NavbarLink
                name="Estimate Calculator"
                targetPage="/estimate-calculator"
                currentPage={props.currentPage}
              />

              <NavbarLink
                name="Estimate History"
                targetPage="/estimate-history"
                currentPage={props.currentPage}
              />

              {[ADMIN_ROLE, OPERATIONS_ROLE].includes(context.role) && (
                <NavbarLink
                  name="Delay Events"
                  targetPage="/delay-events"
                  currentPage={props.currentPage}
                />
              )}

              {[ADMIN_ROLE].includes(context.role) && (
                <NavbarLink
                  name="Global Configuration"
                  targetPage="/global-configuration"
                  currentPage={props.currentPage}
                />
              )}
            </ul>

            <div className="mx-2 pull-right">
              <SignOutButton />
            </div>
          </Fragment>
        )}
      </div>
    </nav>
  );
}

export default Navbar;

Navbar.propTypes = {
  currentPage: PropTypes.string,
};
